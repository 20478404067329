import {IProgramsRepository} from './IProgramsRepository';
import {program, programs} from './ProgramsQueries';
import {updateProgram, createProgram} from './ProgramsMutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class ProgramsRepository implements IProgramsRepository {
  async getPrograms(): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: programs,
        variables: {},
      });
      console.log('getPrograms->', response);

      return response.data?.programs.items;
    } catch (error) {
      console.log('getPrograms repository Error:', error);
      throw new Error(error);
    }
  }

  async getProgramById(programId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: program,
        variables: {
          id: programId,
        },
      });
      console.log('getPrograms->', response);

      return response.data?.program;
    } catch (error) {
      console.log('getProgram repository Error:', error);
      throw new Error(error);
    }
  }

  async updateProgram(
    programId: string,
    updatedAt: string,
    program: any,
  ): Promise<any> {
    try {
      const updateProgramResponse: GraphQLResult<any> = await client.graphql({
        query: updateProgram,
        variables: {
          id: programId,
          updatedAt: updatedAt,
          input: program,
        },
      });
      console.log('updateProgram->', updateProgramResponse);

      return updateProgramResponse?.data?.updateProgram;
    } catch (error) {
      console.log('getProgram repository Error:', error);
      throw new Error(error);
    }
  }

  async createProgram(programProgram: any): Promise<any> {
    try {
      const createProgramResponse: GraphQLResult<any> = await client.graphql({
        query: createProgram,
        variables: {
          input: programProgram,
        },
      });
      console.log('createProgramResponse->', createProgramResponse);

      return createProgramResponse.data?.createProgram;
    } catch (error) {
      console.log('Fetch program Error: ', error);
      throw new Error(error);
    }
  }
}
