import {
  Col,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Statistic,
  StatisticProps,
  Table,
  Typography,
} from 'antd';
import {Link, useLocation, useParams} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment, {Moment} from 'moment';

import {DiscountCodeService} from '../DiscountCode/DiscountCodeService';
import {useEffect, useRef, useState} from 'react';
import {
  Discountcode,
  DiscountcodeConnection,
  DiscountUsage,
  Level,
  PikaDoPartners,
  SubscriptionsByDiscountCode,
} from '../../graphql/API';

import {useDispatch} from 'react-redux';
import {getCurrentUserPermission, Permissions} from '../../utils';
import {NotificationPlacement} from 'antd/lib/notification';

import CountUp from 'react-countup';

const discountCodeService = new DiscountCodeService();

export const DiscountCodeForPartnerDetails = () => {
  const {Text} = Typography;
  const dispatch = useDispatch();
  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();
  console.log('params', location);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [discountCodeSubscripotions, setDiscountCodeSubscripotions] =
    useState<SubscriptionsByDiscountCode>();

  const [currentUserPermission, setCurrentUserPermission] =
    useState<Permissions>();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getCurrentUserPermission().then((p: Permissions) => {
      console.log('currentUserPermission', p);
      setCurrentUserPermission(p);
    });
  }, []);

  useEffect(() => {
    if (currentUserPermission) {
      if (currentUserPermission === 'partner') {
        discountCodeService
          .getSubscriptionsByDiscountCode(location?.state?.id)
          .then((resp: SubscriptionsByDiscountCode) => {
            console.log('resp', resp);
            setDiscountCodeSubscripotions(resp);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserPermission]);

  const openErrorNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.error({
      // key,
      message: 'Cannot generate codes',
      description: message,
      placement,
    });
  };

  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator="," style={{fontSize: 50}} />
  );

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          marginTop: 10,
        }}>
        <Text
          style={{
            fontSize: 40,
            marginBottom: 20,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: '#000',
            borderStyle: 'solid',
            padding: 15,
          }}>
          Code Name: {location?.state?.codeName}
        </Text>
      </div>
      {!isError && !isLoading && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                style={{fontSize: 15, marginInlineStart: 15}}
                title="Discount Percentage %"
                value={discountCodeSubscripotions.discountcode.valuePercentage}
                formatter={formatter}
              />
            </Col>
            <Col span={12}>
              <Statistic
                style={{marginInlineStart: 10}}
                title="Number of subscriptions"
                value={discountCodeSubscripotions.numOfSubscriptions}
                precision={2}
                formatter={formatter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state.school && (
                  <>
                    <Text
                      style={{fontSize: 16, display: 'block'}}
                      strong={true}>
                      School Name
                    </Text>
                    <Text style={{fontSize: 16}}>
                      {location?.state.school.name}
                    </Text>
                  </>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state.level && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Level
                    </Text>
                    <Text>{location?.state.level.name}</Text>
                  </>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.validFrom && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Valid From
                    </Text>
                    <Text>
                      {moment(location?.state.validFrom).format('LLLL')}
                    </Text>
                  </>
                )}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.expirationDate && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Expire In
                    </Text>
                    <Text>
                      {moment(location?.state?.expirationDate).format('LLLL')}
                    </Text>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.curriculum && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      curriculum
                    </Text>
                    <Text>{location?.state?.curriculum.name}</Text>
                  </>
                )}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.expirationDate && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Package
                    </Text>
                    <Text>
                      {JSON.parse(location?.state.package.name_i18n)['en']}
                    </Text>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>discount codes fetching error ⚠️</Title>}
    </div>
  );
};
