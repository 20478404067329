import {IStudentsRepository} from './IStudentsRepository';

import {Student} from '../../graphql/API';
import {
  students,
  student,
  studentSessions,
  customGetUserById,
  studentWithFullReport,
  customGetSyllablesByLetters,
} from './StudentsQueries';
import {updateStudent} from './StudentsMutations';
import {user} from '../../graphql/queries';
import {generateFullReportAsString} from '../../graphql/mutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class StudentsRepository implements IStudentsRepository {
  async getUserById(userId: string): Promise<any> {
    const response: GraphQLResult<any> = await client.graphql({
      query: customGetUserById,
      variables: {
        id: userId,
      },
    });

    return response.data?.user;
  }
  async getSessionsStudentById(studentId: string): Promise<any> {
    const response: GraphQLResult<any> = await client.graphql({
      query: studentSessions,
      variables: {
        id: studentId,
      },
    });

    return response.data?.student;
  }

  async getStudents(
    nextToken: string,
    searchKey?: string,
    searchValue?: string,
  ): Promise<Student | any> {
    try {
      const filter =
        searchKey === 'ID'
          ? {id: {beginsWith: searchValue}}
          : {name: {contains: searchValue}};

      const response: GraphQLResult<any> = await client.graphql({
        query: students,
        variables: {
          filter,
          nextToken,
          ...(searchValue?.length > 0 ? null : {scannedCount: 101}),
        },
      });

      return response.data?.students;
    } catch (e) {
      console.log('getStudents Error->', e);
    }
  }
  async getStudentById(studentId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: student,
        variables: {
          id: studentId,
        },
      });

      return response.data?.student;
    } catch (error) {
      console.log('Fetch student Error: ', error);
    }
  }

  async getStudentWithFillReportByStudentId(studentId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: studentWithFullReport,
        variables: {
          id: studentId,
        },
      });

      return response.data?.student;
    } catch (error) {
      console.log('Fetch student Error: ', error);
    }
  }

  async updateStudent(
    studentId: string,
    updatedAt: string,
    student: any,
  ): Promise<any> {
    try {
      const updateStudentResponse: GraphQLResult<any> = await client.graphql({
        query: updateStudent,
        variables: {
          id: studentId,
          updatedAt: updatedAt,
          input: student,
        },
      });

      return updateStudentResponse?.data?.updateStudent;
    } catch (error) {
      console.log('Fetch student Error: ', error);
    }
  }

  async getSyllablesByLetters(lettersList: Array<string>): Promise<any> {
    if (lettersList && lettersList.length !== 0) {
      try {
        const response: GraphQLResult<any> = await client.graphql({
          query: customGetSyllablesByLetters,
          variables: {
            lettersList: lettersList,
          },
        });

        return response.data.getSyllablesByLetters.items;
      } catch (error) {
        console.log('Fetch student Error: ', error);
      }
    }
  }

  async generateStudentFullReport(
    studentId: string,
    statisticId: string,
  ): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: generateFullReportAsString,
        variables: {
          studentId: studentId,
          statisticId: statisticId,
        },
      });

      return response;
    } catch (error) {
      console.log('Fetch student Error: ', error);
    }
  }
}
