import React, {useEffect, useState} from 'react';
import {getFileUrlByKey} from '../../utils';
import {Image} from 'antd';

type ProfilePictureCardProps = {
  thumbnailUri: string | undefined;
  style?: any;
};

export default function ProfilePictureCard({
  thumbnailUri,
  style,
}: ProfilePictureCardProps) {
  const [img, setImg] = useState<string | undefined>(thumbnailUri);

  const fetchImage = async () => {
    if (thumbnailUri) {
      const pathContent = thumbnailUri?.split('/');
      console.log('pathContent', pathContent);

      const bucketName = pathContent[3];
      let uri = thumbnailUri;
      console.log('bucketName11', bucketName);

      try {
        const pathVideo = thumbnailUri?.split('.pikado.io/');
        console.log('thumbnailUri00', pathVideo);

        const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);
        uri = videoProfileUrl.url.toJSON();
        setImg(uri);
        // console.log('uri33', uri);
      } catch (error) {
        console.error('ERRRR:->', error);
      }
    }
  };

  return (
    <>
      <Image
        onError={(error) => {
          console.log('ERRRRRR', error);
          fetchImage();
        }}
        src={img}
        alt="loading..."
        style={
          style
            ? style
            : {
                width: 150,
                height: 70,
                borderRadius: 8,
                zIndex: 1000,
              }
        }
      />
    </>
  );
}
