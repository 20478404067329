import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Input, InputNumber, Row, Select} from 'antd';
import CountriesWithCurrency from 'iso-country-currency';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {Package, Price} from '../../graphql/API';
import {PackagesService} from '../Package';
import {PricesService} from './PricesService';
import {useHistory, useLocation, useParams} from 'react-router-dom';

const pricesService = new PricesService();

export const PriceEdit = () => {
  const history = useHistory();
  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();
  let params: {priceId: string} = useParams();

  const [countries, setCountries] = useState<Array<any>>([]);
  const [isAny, setIsAny] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>(false);

  const [price, setPrice] = useState<Price>();
  const [selectedCounty, setSelectedCounty] = useState<string>('');

  useEffect(() => {
    if (location.state?.price) {
      setPrice(location.state.price);
    } else {
      pricesService
        .getPriceById(params.priceId)
        .then((prc: Price) => {
          setSelectedCounty(prc?.country!);
          prc?.country === 'any' && setIsAny(true);
          setPrice(prc);
        })
        .catch((e) => {
          console.log('err->', e);
          setIsError(true);
        });
    }

    setCountries(CountriesWithCurrency.getAllISOCodes());
  }, []);

  const onChange = (prop: any, value: any) => {
    //@ts-ignore
    setPrice({...price, [prop]: value});
  };

  const selectAnyCountryPrice = () => {
    setSelectedCounty('any');
    onChange('currency', 'USD');
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create Price </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Name
              </Text>
              <Input
                onChange={(event) => onChange('name', event.target.value)}
                value={price?.name}
              />
            </div>
          </Col>

          {price?.package && (
            <Col span={9}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package Name
                </Text>

                <Input
                  disabled={true}
                  contentEditable={false}
                  value={JSON.parse(price?.package?.name_i18n)['en']}
                />
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Country
              </Text>
              {isAny ? (
                <Text>any country</Text>
              ) : (
                <Select
                  disabled={isAny}
                  value={selectedCounty}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a country"
                  optionFilterProp="children"
                  onChange={(c) => {
                    if (isAny) {
                      selectAnyCountryPrice();
                    } else {
                      // @ts-ignore
                      setSelectedCounty(c);
                      onChange(
                        'currency',
                        // @ts-ignore
                        CountriesWithCurrency.getAllInfoByISO(c).currency,
                      );
                    }
                  }}>
                  {countries?.map((country) => {
                    return (
                      <Select.Option value={country.iso}>
                        {country.countryName}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
              <Checkbox
                style={{marginLeft: '50px'}}
                checked={isAny}
                onChange={(event) => {
                  if (event.target.checked) {
                    selectAnyCountryPrice();
                  }
                  setIsAny(event.target.checked);
                }}>
                Any / else county
              </Checkbox>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Currency
              </Text>
              <Input disabled={true} value={price?.currency} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Price
              </Text>
              <InputNumber
                value={price?.price}
                onChange={(pr) => onChange('price', pr)}
              />
            </div>
          </Col>
        </Row>
        {/************************************************************************************************************************/}
      </>
      <Button
        onClick={async () => {
          console.log('priceprice-', price);
          let tempPrice = price;
          // @ts-ignore
          tempPrice.country = selectedCounty;
          pricesService
            .updatePrice(tempPrice)
            .then((resp: any) => {
              console.log('edited rep->', resp);
              alert('Price edited');
              // history.push('/prices');
            })
            .catch((err) => {
              console.log('create price error: ', err);
            });
        }}>
        Edit
      </Button>
    </div>
  );
};
