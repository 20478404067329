import {useState} from 'react';
import Title from 'antd/es/typography/Title';
import {Button, Col, Input, Row, Space, Typography} from 'antd';
import {useHistory} from 'react-router-dom';
import {CreateAssetBlock} from '../../components';

import {AssetType} from '../../graphql/API';

import {SyllablesService} from './SyllablesService';
import {UploadFileType} from '../Lesson/StorageService';

const syllableService = new SyllablesService();

export const SyllableCreate = () => {
  const history = useHistory();
  const {Title, Text} = Typography;
  const [letters, setLetters] = useState<string>('');
  // @ts-ignore
  const [correctSyllableAsset, setCorrectSyllableAsset] = useState<any>({
    type: AssetType.VIDEO,
    thumbnailUri: '',
    fileUri: '',
  });

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create syllable</Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
              <Text>Test name </Text>
              <Input
                value={letters}
                onChange={(e) => {
                  setLetters(e.target.value);
                }}
                placeholder="Syllable letters"
              />
            </div>
          </Col>

          <Col span={12}>
            <CreateAssetBlock
              syllableName={letters}
              uploadFileType={UploadFileType.SYLLABLE_VIDEO}
              asset={correctSyllableAsset}
              defaultAssetType={correctSyllableAsset?.type ?? null}
              // @ts-ignore TODO be checked
              onChange={(selectedAsset) => {
                console.log('selectedAsset', selectedAsset);
                setCorrectSyllableAsset(selectedAsset);
              }}
            />
          </Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          console.log('current letters->', letters);
          console.log('current correctSyllableAsset->', correctSyllableAsset);

          if (letters.length === 0) {
            alert('Please enter the letters');
          }
          if (correctSyllableAsset.fileUri.length === 0) {
            alert('Please enter the file url');
          }
          if (correctSyllableAsset.thumbnailUri.length === 0) {
            alert('Please enter the thumbnail url');
          }

          let reponse = await syllableService
            .createSyllable(letters, correctSyllableAsset)
            .catch((err: any) => {
              console.log('syllable creation error', err);
              alert(`Syllable creation failed ${err}`);
            });
          console.log('reponse--->', reponse);
          alert('Syllable created');
          history.push('/syllables');
        }}>
        Create
      </Button>
    </div>
  );
};
