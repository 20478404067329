import {IPackagesService} from './IPackagesService';
import {PackagesRepository} from './PackagesRepository';
import {DivisionType, Package} from '../../graphql/API';
import {AssetsService} from '../Asset';
import {PricesService} from '../Price';

export class PackagesService implements IPackagesService {
  packagesRepository: PackagesRepository;
  assetsService: AssetsService;
  pricesService: PricesService;
  constructor() {
    this.packagesRepository = new PackagesRepository();
    this.assetsService = new AssetsService();
    this.pricesService = new PricesService();
  }

  async getPackages(): Promise<Array<Package> | any> {
    let packages = await this.packagesRepository.getPackages();
    // console.log('getPackages from service -->', packages);
    return packages;
  }

  async getPackageById(packageId: string): Promise<Package> {
    let programPackage = await this.packagesRepository.getPackageById(
      packageId,
    );
    return programPackage;
  }

  async updatePackage(programPackage: Package | any): Promise<any> {
    /** Deprecated
    let coverAssetId = programPackage.coverAsset.id;
    await this.assetsService.updateAsset(programPackage.coverAsset);
     */

    console.log('programPackage', programPackage);
    let pkgId = programPackage.id;
    let pkgUpdatedAt = programPackage.updatedAt;
    let promoAssetId = programPackage.promoAsset.id;
    let programId = programPackage.program.id;
    let enrollmentPackageId = programPackage?.enrollmentPackage?.id;
    console.log('to be updated->', programPackage);
    let recordIds = [];
    if (programPackage.records) {
      await Promise.all(
        programPackage.records?.map(async (record) => {
          if (record.id) {
            await this.assetsService.updateAsset(record);
            recordIds.push(record.id);
          } else {
            let createRecordAssetRes = await this.assetsService.createAsset(
              record,
            );
            console.log('createRecordAssetRes->', createRecordAssetRes);
            recordIds.push(createRecordAssetRes);
          }
        }),
      );
    }
    let activityIds = [];

    if (programPackage.activities) {
      await Promise.all(
        programPackage.activities?.map(async (activity) => {
          console.log('activity->', activity);
          if (activity.id) {
            let updateAssetRes = await this.assetsService.updateAsset(activity);
            activityIds.push(activity.id);
          } else {
            let createAssetRes = await this.assetsService.createAsset(activity);
            activityIds.push(createAssetRes);
          }
        }),
      );
    }
    await this.assetsService.updateAsset(programPackage.promoAsset);
    console.log('1--programPackage->', programPackage);
    let pkg = programPackage;
    pkg.promoAssetId = pkg.promoAsset.id;
    //pkg.coverAssetId = pkg.coverAsset.id;
    pkg.programId = programId;
    pkg.enrollmentPackageId = enrollmentPackageId;
    pkg.activityIds = activityIds;
    pkg.recordIds = recordIds;
    delete pkg.id;
    delete pkg.updatedAt;
    delete pkg.coverAsset;
    delete pkg.promoAsset;
    delete pkg.createdAt;
    delete pkg.price;
    delete pkg.program;
    delete pkg.enrollmentPackage;
    delete pkg.activities;
    delete pkg.records;
    console.log('2--programPackage->', programPackage);
    console.log('final pkg->', pkg);
    return await this.packagesRepository.updatePackage(pkgId, pkgUpdatedAt, {
      ...pkg,
      promoAssetId: promoAssetId,
    });
  }

  async createPackage(programPackage: any): Promise<any> {
    console.log('recieveed programPackage->', programPackage);
    try {
      let enrollmentPackageId = programPackage?.enrollmentPackage?.id;

      let packageObj: any = {
        programId: programPackage.program?.id,
        onlyAI: programPackage.onlyAI ? programPackage.onlyAI : false,
        details_i18n: programPackage.details_i18n,
        benefits_i18n: programPackage.benefits_i18n,
        name_i18n: programPackage.name_i18n,
        description_i18n: programPackage.description_i18n,
        isFree: programPackage.isFree ? programPackage.isFree : false,
        isNative: programPackage.isNative ? programPackage.isNative : false,
        packageDuration: programPackage.packageDuration
          ? programPackage.packageDuration
          : 1,
        discountDescription: programPackage.discountDescription
          ? programPackage.discountDescription
          : '',
        isRecommended: programPackage.isRecommended
          ? programPackage.isRecommended
          : false,
        sessionPerWeek: programPackage.sessionPerWeek
          ? programPackage.sessionPerWeek
          : [1],
        numberOfSessions: programPackage.numberOfSessions
          ? programPackage.numberOfSessions
          : 0,
        isActive: programPackage.isActive ? programPackage.isActive : false,
        enrollmentPackageId: '',
        typePackage: programPackage.typePackage
          ? programPackage.typePackage
          : DivisionType.ONE_TO_ONE,
      };

      enrollmentPackageId
        ? (packageObj.enrollmentPackageId = enrollmentPackageId)
        : delete packageObj.enrollmentPackageId;

      let recordIds = [];
      if (programPackage.records) {
        await Promise.all(
          programPackage.records?.map(async (record) => {
            let createRecordAssetRes = await this.assetsService.createAsset(
              record,
            );
            recordIds.push(createRecordAssetRes);
          }),
        );
      }
      let activityIds = [];

      if (programPackage.activities) {
        await Promise.all(
          programPackage.activities?.map(async (activity) => {
            let createAssetRes = await this.assetsService.createAsset(activity);
            activityIds.push(createAssetRes);
          }),
        );
      }
      packageObj.promoAssetId = await this.assetsService.createAsset(
        programPackage.promoAsset,
      );
      packageObj.activityIds = activityIds;
      packageObj.recordIds = recordIds;
      console.log('packageObj to create->', packageObj);

      //TODO: Add validators
      let createPackageResponse = await this.packagesRepository.createPackage(
        packageObj,
      );

      console.log('createPackageResponse->', createPackageResponse);
      let initalPrice = {
        packageId: createPackageResponse.id,
        name: `${programPackage.name} any`,
        price: 0,
        currency: 'USD',
        country: 'any',
      };
      let createPriceResp = await this.pricesService.createPrice(initalPrice);
      console.log('createPriceResp->', createPriceResp);
      return createPackageResponse;
    } catch (e) {
      return new Promise((resolve, reject) =>
        reject(`Creating package error: ${e}`),
      );
    }
  }
}
