import {IQuestionsService} from './IQuestionsService';
import {QuestionsRepository} from './QuestionsRepository';
import {AssetUpdate, Question, QuestionUpdate} from '../../graphql/API';
import {AssetsRepository} from '../Asset/AssetsRepository';

export class QuestionsService implements IQuestionsService {
  questionsRepository: QuestionsRepository;
  assetsRepository: AssetsRepository;
  constructor() {
    this.questionsRepository = new QuestionsRepository();
    this.assetsRepository = new AssetsRepository();
  }
  async createQuestion(question: Question): Promise<any> {
    console.log('Qchoics', question.customChoices);
    let questionObj = {
      name: question.name,
      summary: question.summary,
      description: question.description,
      text: question.text,
      correctAssetId: '',
      assetId: '',
      programType: question.programType,
      choices: question.choices?.length !== 0 ? question.choices : [],
      customChoices:
        question?.customChoices &&
        Object.keys(question?.customChoices).length > 0
          ? JSON.stringify(question.customChoices)
          : undefined,
      keywords: [],
      type: question.type,
      textAI: question.textAI,
    };
    if (
      question.asset.fileUri.length !== 0 ||
      question.asset.thumbnailUri.length !== 0
    ) {
      questionObj.assetId = await this.assetsRepository.createAsset(
        question.asset!,
      );
    }
    if (question.correctAsset?.fileUri?.length !== 0) {
      questionObj.correctAssetId = await this.assetsRepository.createAsset(
        question.correctAsset!,
      );
    }

    return await this.questionsRepository.createQuestion(questionObj);
  }

  async updateQuestion(
    id: string,
    question: Question,
    updatedAt: string,
  ): Promise<any> {
    let questionObj: QuestionUpdate = {
      name: question.name,
      summary: question.summary,
      description: question.description,
      text: question.text,
      textAI: question.textAI,
      type: question.type,
      choices: question.choices?.length !== 0 ? question.choices : [],
      customChoices:
        question?.customChoices &&
        Object.keys(question?.customChoices).length > 0
          ? JSON.stringify(question.customChoices)
          : undefined,
      videoAISessionUri: question?.videoAISessionUri,
    };
    console.log('question.asset', question.asset);
    if (
      question.asset &&
      !question?.asset?.id &&
      (question?.asset.thumbnailUri || question?.asset.fileUri)
    ) {
      questionObj.assetId = await this.assetsRepository.createAsset(
        question.asset!,
      );
    } else if (question?.asset?.id) {
      const assetUpdate: AssetUpdate = {
        type: question.asset.type,
        thumbnailUri: question.asset.thumbnailUri,
        fileUri: question.asset.fileUri,
        title_i18n: question.asset.title_i18n,
        subTitle_i18n: question.asset.subTitle_i18n,
        position: question.asset.position,
      };
      await this.assetsRepository.updateAsset(
        question?.asset?.id,
        question?.asset?.updatedAt,
        assetUpdate,
      );
    }
    // if (question.correctAsset?.fileUri?.length !== 0) {
    //   questionObj.correctAssetId = await this.assetsRepository.createAsset(
    //     question.correctAsset!,
    //   );
    // }

    return await this.questionsRepository.updateQuestion(
      id,
      questionObj,
      updatedAt,
    );
  }
}
