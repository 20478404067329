import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spin, Table} from 'antd';
import Title from 'antd/es/typography/Title';

import {Lesson} from '../../graphql/API';
import {LessonsService} from './LessonsService';

const lessonsService = new LessonsService();

export const LessonsTable = () => {
  const [lessons, setLessons] = useState<Array<Lesson> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    lessonsService
      .getLessons()
      .then((resp) => {
        setIsLoading(false);
        setLessons(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const columns: any = [
    {
      title: 'Name',
      dataIndex: ['name'],
      render: (name: string, record: Lesson) => {
        return <Link to={`/lessons/${record.id}/edit`}>{name}</Link>;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Program',
      dataIndex: ['course', 'program', 'flag'],
      render: (flag: string, record: Lesson) => (
        <Link
          to={`/programs/${record.course?.program.id}/edit`}
          target="_blank">
          <img src={flag} />
        </Link>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Level',
      dataIndex: ['course', 'level', 'name'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.localeCompare(b);
      },
    },
    {
      title: 'ID',
      width: '200px',
      dataIndex: 'id',
      render: (id: string) => <Link to={`/lessons/${id}/edit`}>{id}</Link>,
    },
  ];
  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/lessons/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={lessons}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Lessons fetching error ⚠️</Title>}
    </>
  );
};
