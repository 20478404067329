import {useEffect, useState} from 'react';
import {useParams, Link, useLocation, useHistory} from 'react-router-dom';
import {Button, Col, Divider, Input, Row, Select, Spin, Typography} from 'antd';
import {Student, Student_Status} from '../../graphql/API';
import {StudentsService} from './StudentsService';

const studentsService = new StudentsService();

export const StudentEdit = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [student, setStudent] = useState<Student | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {studentId: string} = useParams();
  const history = useHistory();

  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();
  const {Title, Text} = Typography;

  useEffect(() => {
    studentsService
      .getStudentById(params.studentId)
      .then((resp) => {
        setIsLoading(false);
        console.log('RESSPSSS', resp);
        setStudent(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [location.pathname]);

  const onChange = (prop: any, value: any) => {
    //@ts-ignore
    setStudent({...student, [prop]: value});
  };
  const onSave = async () => {
    studentsService
      .updateStudent(student!)
      .then((e) => {
        alert('Student updated');
        history.push('/students/' + student?.id);
      })
      .catch((er) => {
        alert('Student update Error');
        console.log('Student update Error:', er);
      });
  };
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Student </Title>

      {!isError && !isLoading && (
        <>
          <Row>
            <Col span={12}>
              <Text
                style={{
                  marginInline: 20,
                  fontWeight: 'bold',
                }}>
                Student ID: {student?.id}
              </Text>
            </Col>

            <Col span={12}>
              <Text
                style={{fontSize: 16, fontWeight: 'bold', marginInline: 20}}>
                Current Student Level:{' '}
                <b>{student?.level?.name ?? 'unknown'}</b>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student status
                </Text>
                <Select
                  style={{width: '100%'}}
                  placeholder="Please select"
                  //@ts-ignore
                  value={student?.status}
                  onChange={(newStudentStatus) => {
                    onChange('status', newStudentStatus);
                  }}>
                  <Select.Option value={Student_Status.NEW_CUSTOMER}>
                    <p>New customer</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.ADDED_TO_WHATSAPP}>
                    <p>Added to whatsapp</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.CONTACTED_BY_WHATSAPP}>
                    <p>Contacted by whatsapp</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.RECEIVED_CALL}>
                    <p>Received phone call</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.INTERESTED}>
                    <p>Interested to join PikaDo</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.NOT_INTERESTED}>
                    <p>Will not join PikaDo</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.FOLLOW_UP}>
                    <p>Follow up</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.CALLED_WITHOUT_RESPONSE}>
                    <p>Called but no response</p>
                  </Select.Option>
                  <Select.Option value={Student_Status.JOINED}>
                    <p>Joined PikaDo and Paid</p>
                  </Select.Option>
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Assigned to
                </Text>
                <Input
                  value={student?.assignedTo ?? ''}
                  onChange={(event) =>
                    onChange('assignedTo', event.target.value)
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Notes
                </Text>
                <Input.TextArea
                  value={student?.notes ?? ''}
                  onChange={(event) => onChange('notes', event.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student name
                </Text>
                <Text>{student?.name}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Whatsapp number
                </Text>
                <Text>{student?.user?.whatsappNumber}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Parent name
                </Text>
                <Text>{student?.user?.parent?.name}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student Sessions
                </Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Link to={'/student-sessions/' + params.studentId}>
                    Sessions Details
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student enrollments
                </Text>

                {student?.enrollments?.map((e) => {
                  return (
                    <div>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginInline: 10,
                          }}>
                          <Link to={'/enrollments/' + e?.id + '/edit'}>
                            {e?.id}
                          </Link>
                          <Text>Updated: {e.updatedAt}</Text>
                        </div>
                        <Text style={{marginInline: 20}}>
                          paymentStatus: {e.paymentStatus}
                        </Text>
                      </div>
                      <Divider />
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student memberships
                </Text>

                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}>
                  <Link
                    to={{pathname: '/memberships/create', state: {student}}}>
                    Create
                  </Link>
                </Button>

                {student?.memberships?.map((m) => {
                  return (
                    <div>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginInline: 10,
                          }}>
                          <Link to={'/memberships/' + m?.id + '/edit'}>
                            {m?.id}
                          </Link>
                          <Text>Created: {m.createdAt}</Text>
                        </div>
                        <Text>isActive: {m.isActive + ''}</Text>
                        <Text style={{marginInline: 20}}>
                          paymentStatus: {m.paymentStatus}
                        </Text>
                      </div>
                      <Divider />
                    </div>
                  );
                })}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Gender
                </Text>
                <Text>{student?.gender}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Language
                </Text>
                <Link to={'/languages/' + student?.language?.id}>
                  {student?.language?.id}
                </Link>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Age
                </Text>
                <Text>{student?.age}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Joined at
                </Text>
                <Text>{student?.createdAt}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  User
                </Text>
                <Link to={'/users/' + student?.user?.id}>
                  {student?.user?.id}
                </Link>
              </div>
            </Col>
          </Row>
          <Button
            size={'large'}
            style={{float: 'right'}}
            onClick={() => onSave()}>
            Save
          </Button>
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Student fetching error ⚠️</Title>}
    </div>
  );
};
