import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from 'antd';
import Title from 'antd/es/typography/Title';

import {Course, Lesson, LessonUpdate, Level, Program} from '../../graphql/API';
import {LessonsService} from './LessonsService';
import {Loading} from '../../components';
import {ProgramsService} from '../Program';
import {LevelsService} from '../Level';
import {UploadOutlined} from '@ant-design/icons';
import {UploadFile, UploadProps} from 'antd/lib/upload/interface';
import {LessonLevels} from './LessonCreate';
import {useSelector} from 'react-redux';
import Progress from 'antd/es/progress';
import {StorageService, UploadFileType} from './StorageService';

const lessonsService = new LessonsService();
const programService = new ProgramsService();
const storageService = new StorageService();

export const LessonEdit = () => {
  const history = useHistory();

  const [selectedLessonLevel, setSelectedLessonLevel] = useState<LessonLevels>(
    LessonLevels.level_1,
  );

  let progressPercentage = useSelector(
    (state: any) => state?.user?.uploadingProgress,
  );

  const {Title, Text} = Typography;

  const [fileList, setFileList] = useState<UploadFile>();

  const [fileLessonSummaryVideo, setFileLessonSummaryVideo] =
    useState<UploadFile>();
  const [fileLessonSummaryVideoThumbnail, setFileLessonSummaryVideoThumbnail] =
    useState<UploadFile>();

  const [fileLessonPDFthumbnail, setFileLessonPDFthumbnail] =
    useState<UploadFile>();

  const [instructoinPDFthumbnail, setInstructoinPDFthumbnail] =
    useState<UploadFile>();

  const [instructoinPDF, setInstructoinPDF] = useState<UploadFile>();

  const [uploading, setUploading] = useState(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [courses, setCourses] = useState<Array<Course> | []>();

  let params: {lessonId: string} = useParams();
  const [lesson, setLesson] = useState<Lesson>();

  useEffect(() => {
    // need refactor to get program by id
    programService
      .getPrograms()
      .then((progms) => {
        lessonsService
          .getLessonById(params.lessonId)
          .then((_lesson: Lesson) => {
            setLesson(_lesson);

            const _selectedProgram: Program = progms.find(
              (prgm: Program) => prgm.id === _lesson.course.program.id,
            );

            setCourses(_selectedProgram.courses);
            setSelectedCourse(_lesson.course);
          })
          .catch((e) => {
            setIsError(true);
          });
      })
      .catch((err) => {
        console.log('Programs fetchign error ->', err);
        setIsError(true);
      });
  }, []);

  const onChange = (prop: any, value: any) => {
    if (prop.includes('.')) {
      let propList = prop.split('.');
      let p0 = propList[0];
      let p1 = propList[1];
      // @ts-ignore
      setLesson({
        ...lesson,
        // @ts-ignore
        [p0]: {...lesson[p0], [p1]: value},
      });
    } else {
      //@ts-ignore
      setLesson({...lesson, [prop]: value});
    }
  };

  const handleUpload = async (
    fileUri: UploadFile,
    typeUpload: UploadFileType,
  ) => {
    setUploading(true);
    console.log('fileUri.url', fileUri);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.INSTRUCTION_LESSON_PDF:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'pdf/' + selectedLessonLevel,
          );
          onChange('instructionsPDF.fileUri', filePath);
          break;

        case UploadFileType.INSTRUCTION_LESSON_PDF_THUMBNIAL:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'pdf/images/' + selectedLessonLevel,
          );
          onChange('instructionsPDF.thumbnailUri', filePath);
          break;

        case UploadFileType.LESSION_PDF:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'pdf/' + selectedLessonLevel,
          );
          onChange('lessonPDF.fileUri', filePath);

          break;
        case UploadFileType.LESSION_PDF_THUMBNIAL:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'pdf/images/' + selectedLessonLevel,
          );
          onChange('lessonPDF.thumbnailUri', filePath);

          break;
        case UploadFileType.LESSION_SUMMARY_VIDEO:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'videos/' + selectedLessonLevel,
          );
          onChange('summary.fileUri', filePath);
          break;

        case UploadFileType.LESSION_SUMMARY_VIDEO_THUMBNIAL:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'videos/images/' + selectedLessonLevel,
          );
          onChange('summary.thumbnailUri', filePath);
          break;

        default:
          break;
      }

      console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      setUploading(false);
      message.error('upload failed.');
    }
  };

  const props: UploadProps = {
    type: 'select',
    accept: '.pdf',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    customRequest: (file) => {
      console.log('FILEEEEEE', file);
    },
    onRemove: (file) => {
      // const index = fileList.indexOf(file);
      // const newFileList = fileList.slice();
      // newFileList.splice(index, 1);
      setFileList(null);
    },
    beforeUpload: (file) => {
      setFileList(file);

      return false;
    },
  };
  const propsLessonPDFThumbnial: UploadProps = {
    type: 'select',
    accept: '.png,.jpg',
    listType: 'picture',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileLessonPDFthumbnail(null);
    },
    beforeUpload: (file) => {
      setFileLessonPDFthumbnail(file);

      return false;
    },
  };

  const propsLessonSummaryVideoThumbnial: UploadProps = {
    type: 'select',
    accept: '.png,.jpg',
    listType: 'picture',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileLessonSummaryVideoThumbnail(null);
    },
    beforeUpload: (file) => {
      setFileLessonSummaryVideoThumbnail(file);

      return false;
    },
  };
  const propsLessonSummaryVideo: UploadProps = {
    type: 'select',
    accept: '.mp4',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileLessonSummaryVideo(null);
    },
    beforeUpload: (file) => {
      setFileLessonSummaryVideo(file);

      return false;
    },
  };

  const propsInstructionsPDF: UploadProps = {
    type: 'select',
    accept: '.pdf',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      // const index = fileList.indexOf(file);
      // const newFileList = fileList.slice();
      // newFileList.splice(index, 1);
      setInstructoinPDF(null);
    },
    beforeUpload: (file) => {
      setInstructoinPDF(file);

      return false;
    },
  };

  const propsInstructionsPDFThumbnial: UploadProps = {
    type: 'select',
    accept: '.png,.jpg',
    listType: 'picture',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setInstructoinPDFthumbnail(null);
    },
    beforeUpload: (file) => {
      setInstructoinPDFthumbnail(file);

      return false;
    },
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit lesson </Title>
      {lesson ? (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text>Name</Text>
                <Input
                  value={lesson?.name ?? ''}
                  onChange={(event) => onChange('name', event.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Program
                </Text>

                <Input
                  disabled={true}
                  value={
                    lesson?.course?.program?.name_i18n
                      ? JSON.parse(lesson.course.program.name_i18n)['en']
                      : ''
                  }
                />
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Courses
                </Text>
                <Select
                  value={selectedCourse?.id}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a Course"
                  optionFilterProp="children"
                  onChange={(e) => {
                    let _selectedCourse = courses?.find(
                      (course: Course) => course.id === e,
                    );
                    setSelectedCourse(_selectedCourse);
                  }}>
                  {courses?.map((course: Course) => {
                    return (
                      <Select.Option key={course.id} value={course.id}>
                        {course.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Progress
              percent={progressPercentage}
              strokeColor={{'0%': '#108ee9', '40%': '#87d068'}}
            />
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <>
                  <br />
                  <br />
                  {/* <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  defaultFileList={[...fileList]}
                  className="upload-list-inline">
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload> */}
                </>

                <Text style={{display: 'block'}}>Type</Text>
                <Text
                  style={{display: 'block', fontWeight: 'bold'}}
                  strong={true}>
                  PDF
                </Text>
                <Text style={{display: 'block'}} strong={true}>
                  Lesson pdf
                </Text>
                {lesson?.lessonPDF?.fileUri ? (
                  <Input
                    value={lesson?.lessonPDF?.fileUri ?? ''}
                    onChange={(event) => {
                      event.target.value = '';
                      if (!event.target.value) {
                        setFileList(null);
                      }
                      onChange('lessonPDF.fileUri', event.target.value);
                    }}
                  />
                ) : (
                  <Row>
                    <Upload {...props}>
                      <Button
                        disabled={fileList ? true : false}
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(fileList, UploadFileType.LESSION_PDF)
                      }
                      disabled={!fileList}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Upload'}
                    </Button>
                  </Row>
                )}
                <Text style={{display: 'block'}} strong={true}>
                  Lesson PDF thumbnail
                </Text>
                {lesson?.lessonPDF?.thumbnailUri ? (
                  <Input
                    value={lesson?.lessonPDF?.thumbnailUri ?? ''}
                    onChange={(event) => {
                      event.target.value = '';

                      if (!event.target.value) {
                        setFileLessonPDFthumbnail(null);
                      }
                      onChange('lessonPDF.thumbnailUri', event.target.value);
                    }}
                  />
                ) : (
                  <Row>
                    <Upload {...propsLessonPDFThumbnial}>
                      <Button
                        disabled={fileLessonPDFthumbnail ? true : false}
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(
                          fileLessonPDFthumbnail,
                          UploadFileType.LESSION_PDF_THUMBNIAL,
                        )
                      }
                      disabled={!fileLessonPDFthumbnail}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                  </Row>
                )}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}}>Type</Text>
                <Text
                  style={{display: 'block', fontWeight: 'bold'}}
                  strong={true}>
                  Video
                </Text>
                <Text style={{display: 'block'}} strong={true}>
                  Lesson summary video
                </Text>

                {lesson?.summary?.fileUri ? (
                  <Input
                    value={lesson?.summary?.fileUri ?? ''}
                    onChange={(event) => {
                      event.target.value = '';

                      if (!event.target.value) {
                        setFileLessonSummaryVideo(null);
                      }
                      onChange('summary.fileUri', event.target.value);
                    }}
                  />
                ) : (
                  <Row>
                    <Upload {...propsLessonSummaryVideo}>
                      <Button
                        disabled={fileLessonSummaryVideo ? true : false}
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(
                          fileLessonSummaryVideo,
                          UploadFileType.LESSION_SUMMARY_VIDEO,
                        )
                      }
                      disabled={!fileLessonSummaryVideo}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                  </Row>
                )}
                <Text style={{display: 'block'}} strong={true}>
                  Lesson summary thumbnail
                </Text>

                {lesson?.summary?.thumbnailUri ? (
                  <Input
                    value={lesson?.summary?.thumbnailUri ?? ''}
                    onChange={(event) => {
                      event.target.value = '';

                      if (!event.target.value) {
                        setFileLessonSummaryVideoThumbnail(null);
                      }
                      onChange('summary.thumbnailUri', event.target.value);
                    }}
                  />
                ) : (
                  <Row>
                    <Upload {...propsLessonSummaryVideoThumbnial}>
                      <Button
                        disabled={
                          fileLessonSummaryVideoThumbnail ? true : false
                        }
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(
                          fileLessonSummaryVideoThumbnail,
                          UploadFileType.LESSION_SUMMARY_VIDEO_THUMBNIAL,
                        )
                      }
                      disabled={!fileLessonSummaryVideoThumbnail}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}}>Type</Text>
                <Text
                  style={{display: 'block', fontWeight: 'bold'}}
                  strong={true}>
                  PDF
                </Text>
                <Text style={{display: 'block'}} strong={true}>
                  Instructions pdf
                </Text>
                {lesson?.instructionsPDF?.fileUri ? (
                  <Input
                    value={lesson?.instructionsPDF?.fileUri ?? ''}
                    onChange={(event) => {
                      event.target.value = '';

                      if (!event.target.value) {
                        setInstructoinPDF(null);
                      }

                      onChange('instructionsPDF.fileUri', event.target.value);
                    }}
                  />
                ) : (
                  <Row>
                    <Upload {...propsInstructionsPDF}>
                      <Button
                        disabled={instructoinPDF ? true : false}
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(
                          instructoinPDF,
                          UploadFileType.INSTRUCTION_LESSON_PDF,
                        )
                      }
                      disabled={!instructoinPDF}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                  </Row>
                )}
                <Text style={{display: 'block'}} strong={true}>
                  Instructions PDF thumbnail
                </Text>

                {lesson?.instructionsPDF?.thumbnailUri ? (
                  <Input
                    value={lesson?.instructionsPDF?.thumbnailUri ?? ''}
                    onChange={(event) => {
                      event.target.value = '';

                      if (!event.target.value) {
                        setInstructoinPDFthumbnail(null);
                      }

                      onChange(
                        'instructionsPDF.thumbnailUri',
                        event.target.value,
                      );
                    }}
                  />
                ) : (
                  <Row>
                    <Upload {...propsInstructionsPDFThumbnial}>
                      <Button
                        disabled={instructoinPDFthumbnail ? true : false}
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(
                          instructoinPDFthumbnail,
                          UploadFileType.INSTRUCTION_LESSON_PDF_THUMBNIAL,
                        )
                      }
                      disabled={!instructoinPDFthumbnail}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Loading />
      )}
      <Button
        onClick={async () => {
          const clonedLesson = cloneDeep(lesson);

          const tempLesson: Lesson = {
            ...clonedLesson,
            course: selectedCourse,
          };
          console.log('inside create', tempLesson);
          await lessonsService
            .updateLesson(tempLesson)
            .then((resp: any) => {
              console.log('tmpe after!!->', resp);
              alert('Lesson edited');
              history.go(0);
            })
            .catch((err) => {
              alert('Lesson failed');
              console.log('upadate errpr PACKAGE EDIT->', err);
            });
        }}>
        Edit
      </Button>

      {isError && <Title>Lesson fetching error ⚠️</Title>}
    </div>
  );
};
