import {useEffect, useState} from 'react';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import {useHistory, useLocation, useParams, Link} from 'react-router-dom';
import {
  Membership,
  Level,
  Package,
  Status,
  Student,
  Test,
  Session,
  Group,
} from '../../graphql/API';
import {StudentsService} from '../Students';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {TestsService} from '../Test';
import {MembershipsService} from './MembershipsService';
import {resolveI18nObject} from '../../utils';
import {cloneDeep} from 'lodash';
import {EnrollmentsService} from '../Enrollments/EnrollmentsService';

const packagesService = new PackagesService();
const membershipsService = new MembershipsService();
const studentsService = new StudentsService();
const enrollmentsService = new EnrollmentsService();

export const MembershipEdit = () => {
  const history = useHistory();
  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const {Title, Text} = Typography;
  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);

  let params: {membershipId: string} = useParams();
  const [membership, setMembership] = useState<Membership>();

  useEffect(() => {
    packagesService
      .getPackages()
      .then((pkgs) => {
        setPackages(pkgs);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    membershipsService
      .getMembershipById(params.membershipId)
      .then((mmbrship: Membership) => {
        setMembership(mmbrship);
      })
      .catch((e) => {
        setIsError(true);
      });
  }, []);

  const onChange = (prop: any, value: any) => {
    //@ts-ignore
    setMembership({...membership, [prop]: value});
  };
  console.log('membership', membership);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit membership </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                student ID
              </Text>
              <Link to={'/students/' + membership?.student?.id + '/edit'}>
                {membership?.student?.id}
              </Link>
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Student Name
              </Text>
              <Input
                disabled
                onChange={(event) =>
                  onChange('promo.fileUri', event.target.value)
                }
                value={membership?.student?.name}
              />
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              <Select
                value={membership?.package?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(pkgId) => {
                  let selectedPackage = packages.find(
                    // @ts-ignore
                    (pkg) => pkg.id === pkgId,
                  );
                  onChange('package', selectedPackage);
                }}>
                {packages?.map((pkg) => (
                  <Select.Option value={pkg.id!}>
                    {resolveI18nObject(pkg.name_i18n)}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={5}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Start Date
              </Text>
              <Text style={{display: 'block'}}>{membership?.startDate}</Text>
            </div>
          </Col>
          <Col span={5}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                End Date
              </Text>
              <Text style={{display: 'block'}}>{membership?.endDate}</Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment status
              </Text>
              <Select
                value={membership?.paymentStatus}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(pymtStatus) =>
                  onChange('paymentStatus', pymtStatus)
                }>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Checkbox
                checked={membership?.isActive}
                onChange={(event) =>
                  onChange('isActive', event.target.checked)
                }>
                active?
              </Checkbox>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Checkbox
                checked={membership?.isFullFilled}
                onChange={(event) =>
                  onChange('isFullFilled', event.target.checked)
                }>
                Is Fully Booked Groups
              </Checkbox>
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Number of sessions / week
              </Text>
              <Text style={{display: 'block'}}>
                {membership?.sessionPerWeek}
              </Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Curriculum Name
              </Text>
              <Text style={{display: 'block'}}>
                {membership?.curriculum?.name}
              </Text>
            </div>
          </Col>

          <Col span={5}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Discount Code
              </Text>
              {membership?.discountcode?.codeName ? (
                <Link
                  style={{marginInlineStart: 5}}
                  to={'/discountcode/' + membership?.discountcode?.id}>
                  {membership?.discountcode?.codeName}
                </Link>
              ) : (
                <Text
                  strong
                  style={{
                    backgroundColor: 'purple',
                    color: 'white',
                    padding: 5,
                  }}>
                  NO CODE Applied
                </Text>
              )}
              <Text style={{display: 'block'}}>{}</Text>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Reserved Groups ({membership?.groups?.length})
              </Text>
              <Row>
                {membership?.groups?.map((grp: Group) => (
                  <>
                    <Col span={12}>
                      <Text>{grp.name}</Text>
                    </Col>
                    <Col span={12}>
                      <Link
                        style={{marginInlineStart: 5}}
                        to={'/group/' + grp?.id + '/edit'}>
                        {grp?.id}
                      </Link>
                    </Col>
                  </>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <div style={{margin: 20}}>
            <Text style={{display: 'block'}} strong={true}>
              Sessions Assign in membership
            </Text>
            <Row>
              {membership?.sessions?.map((sess: Session) => (
                <>
                  <Col span={7}>
                    <Text style={{display: 'block', marginBottom: 5}}>
                      {sess.name}
                    </Text>
                  </Col>
                  <Col span={7}>
                    <Text style={{display: 'block', marginBottom: 5}}>
                      {sess.id}
                    </Text>
                  </Col>

                  <Col span={2}>
                    <Text
                      style={{
                        color: 'white',
                        display: 'block',
                        backgroundColor:
                          sess.status === Status.SUSPENDED ? 'red' : 'green',
                        padding: 4,
                        marginBottom: 3,
                      }}
                      strong>
                      {sess.status}
                    </Text>
                  </Col>

                  <Col span={7}>
                    <Link
                      to={'/group/' + sess?.group?.id + '/edit'}
                      style={{marginInlineStart: 7}}>
                      {sess?.group?.id}
                    </Link>
                  </Col>
                </>
              ))}
            </Row>
          </div>
        </Row>
      </>
      <Button
        onClick={async () => {
          console.log('saved membership->', membership);
          let tempMembership = cloneDeep(membership);

          if (membership?.package?.enrollmentPackage) {
            const selectedStudent = await studentsService.getStudentById(
              membership.student.id,
            );

            const selectedRecentlyEnrollment =
              selectedStudent?.enrollments?.reduce(function (a, b) {
                return a.updatedAt > b.updatedAt ? a : b;
              });

            if (selectedRecentlyEnrollment?.package?.packageDuration >= 90) {
            } else {
              const updateEnrollment = {
                packageId: membership?.package?.enrollmentPackage?.id,
                startDate: new Date().toISOString().split('T')[0],
                paymentStatus: membership.paymentStatus,
              };
              await enrollmentsService.updateEnrollment(
                selectedRecentlyEnrollment?.id,
                selectedRecentlyEnrollment?.updatedAt,
                updateEnrollment,
              );
            }
          }

          await membershipsService
            .updateMembership(
              membership.id,
              membership.updatedAt,
              tempMembership,
            )
            .then((e: any) => {
              alert('Membership edited');
              history.go(0);
            })
            .catch((err) => {
              console.log('upadate errpr ->', err);
            });
        }}>
        Edit
      </Button>
    </div>
  );
};
