import {ICurriculumsService} from './ICurriculumsService';
import {CurriculumsRepository} from './CurriculumsRepository';
import {
  CourseInsert,
  Curriculum,
  CurriculumInsert,
  Level,
  PlanInsert,
} from '../../graphql/API';
import {LevelsRepository} from '../Level/LevelsRepository';
import {PlansRepository} from '../Plans/PlansRepository';
import {CoursesRepository} from '../Courses/CoursesRepository';

export class CurriculumsService implements ICurriculumsService {
  curriculumsRepository: CurriculumsRepository;
  levelsRepository: LevelsRepository;
  planRepository: PlansRepository;
  courseRepository: CoursesRepository;

  constructor() {
    this.curriculumsRepository = new CurriculumsRepository();
    this.levelsRepository = new LevelsRepository();
    this.planRepository = new PlansRepository();
    this.courseRepository = new CoursesRepository();
  }

  async getCurriculums(): Promise<any> {
    let curriculums = await this.curriculumsRepository.getCurriculums();
    return curriculums;
  }

  async createCurriculum(
    curriculum: Curriculum,
    selectedProgramId: string,
    selectedLanguageId: string,
  ): Promise<any> {
    let curriculumToCreate: CurriculumInsert = {
      name: curriculum.name,
      totalPlanPeriod: parseInt(curriculum?.totalPlanPeriod + '', 10),
    };

    const createdCurriculum = await this.curriculumsRepository.createCurriculum(
      curriculumToCreate,
    );

    const levels: Level[] = await this.levelsRepository.getLevels();

    //create 8 course with 8 levels
    await Promise.all(
      levels.map(async (lvl: Level) => {
        if (lvl?.number !== 0) {
          const planInsert: PlanInsert = {
            curriculumId: createdCurriculum.id,
          };
          const createdPlan = await this.planRepository.createPlan(planInsert);
          console.log('createdPlan', createdPlan);
          const courseToCreate: CourseInsert = {
            name: 'course - ' + curriculum.name + '- ' + lvl.number,
            levelId: lvl.id,
            languageId: selectedLanguageId,
            planId: createdPlan.id,
            programId: selectedProgramId,
            curriculumId: createdCurriculum.id,
          };
          console.log('courseToCreate', courseToCreate);
          await this.courseRepository.createCourse(courseToCreate);
        }
      }),
    );
  }
}
