import {useEffect, useState} from 'react';
import {useParams, Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Col, Divider, Row, Spin, Typography} from 'antd';
import {Session, Status, Student} from '../../graphql/API';
import {StudentsService} from './StudentsService';
import moment from 'moment-timezone';

const studentsService = new StudentsService();

export const StudentSessionDetails = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [student, setStudent] = useState<Student | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {studentId: string} = useParams();
  const history = useHistory();
  const {Title, Text} = Typography;
  const location = useLocation();

  useEffect(() => {
    studentsService
      .getSessionsStudentById(params.studentId)
      .then((resp) => {
        setIsLoading(false);
        setStudent(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [location.pathname]);

  console.log('params.studentId', location.pathname);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Student Sessions</Title>

      {!isError && !isLoading && (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student name
                </Text>
                <Text>{student?.name}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student sessions
                </Text>

                <Row>
                  <Col span={14}>
                    <div
                      style={{
                        marginTop: 20,
                      }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#999999',
                          marginBottom: 10,
                        }}>
                        Student Sessions
                      </Text>
                      {student?.sessions?.map((session: Session) => (
                        <Row
                          onClick={() => {}}
                          style={{
                            minWidth: 500,
                            minHeight: 130,
                            // backgroundColor: 'red',
                          }}>
                          <Col
                            style={{
                              width: 360,
                              backgroundColor: '#ECECEC',
                              marginBottom: 10,
                              borderRadius: 8,
                              paddingBottom: 20,
                              marginInlineEnd: 5,
                            }}>
                            <div
                              style={{
                                width: '100%',
                                minHeight: 70,
                                alignSelf: 'baseline',
                                flexWrap: 'wrap',
                                display: 'flex',
                                backgroundColor:
                                  session?.status === Status.DONE
                                    ? '#C3E56E'
                                    : session?.status === Status.SUSPENDED
                                    ? '#FF5C63'
                                    : session?.status === Status.DOING
                                    ? '#FFD252'
                                    : '#C4C4C4',
                              }}>
                              <div
                                style={{
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  display: 'flex',
                                  paddingInline: 10,
                                }}>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}>
                                  {session?.name}
                                </Text>
                              </div>
                            </div>
                            <div style={{padding: 10}}>
                              <Text
                                onClick={() => {}}
                                style={{
                                  color: '#A6A6A6',
                                  fontWeight: 'bold',
                                }}>
                                Lesson:
                              </Text>

                              <Text
                                onClick={() => {}}
                                style={{
                                  color: '#000',
                                  fontWeight: 'bold',
                                }}>
                                {' '}
                                {session?.lesson?.name}
                              </Text>
                            </div>
                            <div style={{paddingInline: 10}}>
                              <Text
                                onClick={() => {}}
                                style={{
                                  color: '#A6A6A6',
                                  fontWeight: 'bold',
                                }}>
                                Instructor:
                              </Text>

                              <Text
                                onClick={() => {}}
                                style={{
                                  color: '#000',
                                  fontWeight: 'bold',
                                }}>
                                {' '}
                                {session?.instructor?.name}
                              </Text>
                            </div>

                            <div style={{paddingInline: 10, marginTop: 8}}>
                              <Text
                                onClick={() => {}}
                                style={{
                                  color: '#A6A6A6',
                                  fontWeight: 'bold',
                                  marginInlineEnd: 4,
                                }}>
                                Group:
                              </Text>

                              <Link
                                to={{
                                  pathname:
                                    '/group/' + session.group.id + '/edit',
                                  state: {groupId: session.group.id},
                                }}>
                                {session.group.name}
                              </Link>
                            </div>
                          </Col>
                          <Col>
                            <Text
                              style={{
                                fontWeight: 'bold',
                                color: '#999999',
                                fontSize: 14,
                              }}>
                              {moment(session?.startDateTime).format(
                                'yyyy-MM-DD',
                              )}{' '}
                            </Text>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Student fetching error ⚠️</Title>}
    </div>
  );
};
