import {IPackagesRepository} from './IPackagesRepository';
import {packages, getPackage} from './PackagesQueries';
import {updatePackage, createPackage} from './PackagesMutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class PackagesRepository implements IPackagesRepository {
  async getPackages(): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: packages,
        variables: {},
      });
      console.log('response->', response);

      return response.data?.packages.items;
    } catch (error) {
      console.log('getPackages repository Error:', error);
      throw new Error(error);
    }
  }

  async getPackageById(packageId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: getPackage,
        variables: {
          id: packageId,
        },
      });
      console.log('response->', response);

      return response.data?.package;
    } catch (error) {
      console.log('getPackage repository Error:', error);
      throw new Error(error);
    }
  }

  async updatePackage(
    packageId: string,
    updatedAt: string,
    programPackage: any,
  ): Promise<any> {
    console.log('repo packageId->', packageId);
    console.log('repo programPackage->', programPackage);

    try {
      const updatePackageResponse: GraphQLResult<any> = await client.graphql({
        query: updatePackage,
        variables: {
          id: packageId,
          updatedAt: updatedAt,
          input: programPackage,
        },
      });
      console.log('updatePackageResponse->', updatePackageResponse);

      return updatePackageResponse?.data?.updatePackage;
    } catch (error) {
      console.log('getPackage repository Error:', error);
      throw new Error(error);
    }
  }

  async createPackage(programPackage: any): Promise<any> {
    try {
      const createPackageResponse: GraphQLResult<any> = await client.graphql({
        query: createPackage,
        variables: {
          input: programPackage,
        },
      });
      console.log('createPackageResponse->', createPackageResponse);

      return createPackageResponse.data?.createPackage;
    } catch (error) {
      console.log('Fetch package Error: ', error);
      throw new Error(error);
    }
  }
}
