import React, {useEffect, useState} from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  List,
  Progress,
  Row,
  Select,
  Upload,
  message,
} from 'antd';
import {Player} from 'video-react';

import CountriesWithCurrency from 'iso-country-currency';
import {Image} from 'antd';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseSquareFilled,
  UploadOutlined,
} from '@ant-design/icons';
import {
  AdminGetUserResponse,
  Asset,
  AttributeType,
  Availability,
  AvailabilityInsert,
  Gender,
  Instructor,
  InstructorStatus,
  InstructorUpdate,
  Program,
  Session,
  UserUpdate,
} from '../../graphql/API';
import {ProgramsService} from '../Program';
import {InstructorService} from './InstructorService';
import {useHistory, useParams} from 'react-router-dom';
import {AssetsService} from '../Asset';
import {PlusOutlined} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import {StorageService, UploadFileType} from '../Lesson/StorageService';
import {UploadFile, UploadProps} from 'antd/lib/upload/interface';
import {useSelector} from 'react-redux';
import {AccountService} from '../Accounts/AccountService';
import VideoIntroCard from '../PreInstructor/VideoIntroCard';
import ProfilePictureCard from '../PreInstructor/ProfilePictureCard';

const instructorService = new InstructorService();
const accountService = new AccountService();

const {Option} = Select;

const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const {RangePicker} = DatePicker;
type ListTotalInstructorNumberOfSessionsPerMonth = {
  month: string;
  totalNumberOfSession: number;
  index: number;
};
const storageService = new StorageService();

export const InstructorEdit = () => {
  const history = useHistory();
  let params: {instructorId: string} = useParams();
  const [countries, setCountries] = useState<Array<any>>([]);
  const [programs, setPrograms] = useState<Array<Program> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);

  let progressPercentage = useSelector(
    (state: any) => state?.user?.uploadingProgress,
  );
  const [fileInstructorVideo, setFileInstructorVideo] = useState<UploadFile>();
  const [fileInstructorVideoThumbnail, setFileInstructorVideoThumbnail] =
    useState<UploadFile>();

  const [instructor, setInstructor] = useState({
    id: '',
    updatedAt: '',
    userUpdatedAt: '',
    email: '',
    userId: '',
    promo: null,
    name: '',
    whatsapp: '',
    gender: Gender.MALE,
    speakingLanguages: [],
    spokenLang: '',
    paymentMethod: '',
    timezone: '',
    isNative: null,
    program: undefined,
    currency: '',
    isActive: null,
    status: null,
    birthDate: null,
  });

  const [selectedCounty, setSelectedCounty] = useState<string>('');
  const [avaliableTimeZones, setAvaliableTimeZones] = useState<
    Array<string> | []
  >([]);

  const [
    instructorNumberOfStudiesSessions,
    setInstructorNumberOfStudiesSessions,
  ] = useState<Array<ListTotalInstructorNumberOfSessionsPerMonth> | []>([]);

  const [instructorAvaliablities, setInstructorAvaliablities] = useState<
    Array<Availability> | []
  >([]);

  useEffect(() => {
    async function fetchData() {
      let months = [0, 1, 2];
      let tableInstructorSession: ListTotalInstructorNumberOfSessionsPerMonth[] =
        [];

      console.log('tableInstructorSession', tableInstructorSession);

      await Promise.all(
        months.map(async (period: number, index: number) => {
          console.log('0', period);
          instructorService
            .getSessionsByInstructorIdFromTo(params.instructorId, period)
            .then(async (sessons: Session[]) => {
              console.log('getSessionsByInstructorIdFromTo', sessons.length);

              tableInstructorSession.push({
                month: moment().subtract(period, 'M').format('MMMM YYYY'),
                totalNumberOfSession: sessons.length,
                index,
              });

              tableInstructorSession?.sort(function (
                a: ListTotalInstructorNumberOfSessionsPerMonth,
                b: ListTotalInstructorNumberOfSessionsPerMonth,
              ) {
                console.log('tableInstructorSession->Ss', a);
                return a.index - b.index;
              });
            })
            .catch(() => {});
        }),
      );

      console.log('tableInstructorSession,dd_>', tableInstructorSession);
      setInstructorNumberOfStudiesSessions(tableInstructorSession);
    }

    fetchData();

    axios.get('http://worldtimeapi.org/api/timezone/').then((response: any) => {
      console.log(response);
      setAvaliableTimeZones(response.data);
    });

    let programService = new ProgramsService();
    programService
      .getPrograms()
      .then((programs: any) => {
        setPrograms(programs);
      })
      .catch((error: any) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    setCountries(CountriesWithCurrency.getAllISOCodes());
    instructorService
      .getInstructorById(params.instructorId)
      .then(async (instr: Instructor) => {
        const user: AdminGetUserResponse = await accountService.checkEmail(
          instr.user.cognitoUserId,
        );
        const instructorEmail = user.UserAttributes.find(
          (value: AttributeType) => value.Name === 'email',
        );

        setInstructor({
          id: instr.id,
          updatedAt: instr.updatedAt,
          userUpdatedAt: instr.user.updatedAt,
          promo: instr.promo,
          email: instructorEmail.Value,
          userId: instr.user.id,
          name: instr.name,
          whatsapp: instr.user.whatsappNumber,
          gender: instr.gender,
          speakingLanguages: instr.speakingLanguages ?? [],
          spokenLang: '',
          paymentMethod: instr.paymentMethod,
          timezone: instr.user.timeZone,
          program: instr.program,
          currency: '',
          isNative: instr.isNative,
          isActive: instr.isActive,
          status: instr.status,
          birthDate: instr.birthDate,
        });
        setSelectedCounty(instr.user.country);
        setInstructorAvaliablities([...instr.availabilities]);
      })
      .catch((error) => {
        console.log('ERROR', error);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (prop: any, value: any) => {
    if (prop === 'program') {
      //@ts-ignore
      let selectedProgram = programs.find((p) => p.id === value);
      setInstructor({...instructor, [prop]: selectedProgram});
    } else if (prop === 'promo.fileUri') {
      setInstructor({
        ...instructor,
        promo: {
          ...instructor.promo,
          fileUri: value,
        },
      });
    } else if (prop === 'promo.thumbnailUri') {
      setInstructor({
        ...instructor,
        promo: {
          ...instructor.promo,
          thumbnailUri: value,
        },
      });
    } else {
      setInstructor({...instructor, [prop]: value});
    }
  };

  const selectAnyCountryPrice = () => {
    setSelectedCounty('any');
    onChange('currency', 'USD');
  };

  const propsInstructorVideoThumbnial: UploadProps = {
    type: 'select',
    accept: '.png,.jpg',
    listType: 'picture',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileInstructorVideoThumbnail(null);
    },
    beforeUpload: (file) => {
      setFileInstructorVideoThumbnail(file);

      return false;
    },
  };
  const propInstructorVideo: UploadProps = {
    type: 'select',
    accept: '.mp4',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileInstructorVideo(null);
    },
    beforeUpload: (file) => {
      setFileInstructorVideo(file);

      return false;
    },
  };

  const handleUpload = async (
    fileUri: UploadFile,
    typeUpload: UploadFileType,
  ) => {
    setUploading(true);
    console.log('fileUri.url', fileUri);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.INSTRUCTOR_PROFILE_URL:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            instructor.userId,
          );
          console.log('filePath11', filePath);
          onChange('promo.thumbnailUri', filePath);
          break;

        case UploadFileType.INSTRUCTOR_INTRO_VIDEO:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            instructor.userId,
          );
          console.log('filePath', filePath);
          onChange('promo.fileUri', filePath);
          break;

        default:
          break;
      }

      console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      setUploading(false);
      console.log('adssdadasdassa', error);
      message.error('upload failed.');
    }
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit Instructor </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Email
              </Text>
              <Input
                disabled
                onChange={(event) => onChange('email', event.target.value)}
                value={instructor.email}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Name
              </Text>
              <Input
                onChange={(event) => onChange('name', event.target.value)}
                value={instructor.name}
              />
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                WhatsApp
              </Text>
              <Input
                type={'number'}
                onChange={(event) => onChange('whatsapp', event.target.value)}
                value={instructor.whatsapp}
              />
            </div>
          </Col>
        </Row>

        <Row>
          {/* <div style={{margin: 20}}> */}
          <Progress
            percent={progressPercentage}
            strokeColor={{'0%': '#108ee9', '40%': '#87d068'}}
          />
          <Col span={12}>
            <Text style={{display: 'block'}}>Type</Text>
            <Text style={{display: 'block', fontWeight: 'bold'}} strong={true}>
              Video
            </Text>
            <Text style={{display: 'block'}} strong={true}>
              Introduction Video Url
            </Text>

            {instructor?.promo?.fileUri ? (
              <>
                <Input
                  style={{marginBottom: 20}}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setFileInstructorVideo(null);
                    }
                    onChange('promo.fileUri', event.target.value);
                  }}
                  value={instructor?.promo?.fileUri}
                />
                <VideoIntroCard
                  thumbnailUri={instructor?.promo?.thumbnailUri}
                  fileUri={instructor?.promo?.fileUri}
                />
              </>
            ) : (
              <Row>
                <Upload {...propInstructorVideo}>
                  <Button
                    disabled={fileInstructorVideo ? true : false}
                    icon={<UploadOutlined />}>
                    Select File
                  </Button>
                </Upload>

                <Button
                  type="primary"
                  onClick={() =>
                    handleUpload(
                      fileInstructorVideo,
                      UploadFileType.INSTRUCTOR_INTRO_VIDEO,
                    )
                  }
                  disabled={!fileInstructorVideo}
                  loading={uploading}>
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Row>
            )}
          </Col>
          <Col span={12}>
            <Text style={{display: 'block'}} strong={true}>
              Profile Url
            </Text>

            {instructor?.promo?.thumbnailUri ? (
              <>
                <Input
                  value={instructor?.promo?.thumbnailUri ?? ''}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setFileInstructorVideoThumbnail(null);
                    }
                    onChange('promo.thumbnailUri', event.target.value);
                  }}
                />
                <ProfilePictureCard
                  thumbnailUri={instructor?.promo?.thumbnailUri}
                  style={{
                    marginLeft: 20,
                    width: 500,
                    height: 500,
                    borderRadius: 8,
                  }}
                />
              </>
            ) : (
              <Row>
                <Upload {...propsInstructorVideoThumbnial}>
                  <Button
                    disabled={fileInstructorVideoThumbnail ? true : false}
                    icon={<UploadOutlined />}>
                    Select File
                  </Button>
                </Upload>

                <Button
                  type="primary"
                  onClick={() =>
                    handleUpload(
                      fileInstructorVideoThumbnail,
                      UploadFileType.INSTRUCTOR_PROFILE_URL,
                    )
                  }
                  disabled={!fileInstructorVideoThumbnail}
                  loading={uploading}>
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Row>
            )}
          </Col>
          {/* </div> */}
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Status
              </Text>
              <Select
                //@ts-ignore
                value={instructor.status}
                showSearch
                style={{width: '100%'}}
                placeholder="Select status"
                optionFilterProp="children"
                onChange={(status) => onChange('status', status)}>
                {[
                  InstructorStatus.NEW,
                  InstructorStatus.PENDING,
                  InstructorStatus.APPROVED,
                  InstructorStatus.DECLINED,
                  InstructorStatus.SUSPENDED,
                ]?.map((status) => (
                  <Select.Option value={status}>{status}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Gender
              </Text>
              <Select
                //@ts-ignore
                value={instructor.gender}
                showSearch
                style={{width: '100%'}}
                placeholder="Select Gender"
                optionFilterProp="children"
                onChange={(gender) => onChange('gender', gender)}>
                {[Gender.MALE, Gender.FEMALE, Gender.NONE]?.map((gender) => (
                  <Select.Option value={gender}>{gender}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              margin: 20,
            }}>
            <Checkbox
              checked={instructor?.isNative}
              onChange={(event) => onChange('isNative', event.target.checked)}>
              Is native ?
            </Checkbox>
          </div>
        </Row>
        <Row>
          <div
            style={{
              margin: 20,
            }}>
            <Checkbox
              checked={instructor?.isActive}
              onChange={(event) => onChange('isActive', event.target.checked)}>
              Active ?
            </Checkbox>
          </div>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Spoken language
              </Text>

              <Select
                mode="multiple"
                style={{width: '100%'}}
                value={instructor.speakingLanguages}
                placeholder="Enter Speaking languages"
                onChange={(selectedItem) => {
                  onChange('speakingLanguages', selectedItem);
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{margin: '4px 0'}} />
                    <div
                      style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                      <Input
                        style={{flex: 'auto'}}
                        value={instructor.spokenLang}
                        onChange={(event) =>
                          onChange('spokenLang', event.target.value)
                        }
                      />
                      <a
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const tempSpeakingLanguages = [
                            ...instructor.speakingLanguages,
                            instructor.spokenLang,
                          ];
                          onChange('speakingLanguages', tempSpeakingLanguages);
                          // onChange('spokenLang', null);
                        }}>
                        <PlusOutlined /> Add New language
                      </a>
                    </div>
                  </div>
                )}>
                {instructor?.speakingLanguages?.map((item: string) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment Method
              </Text>
              <Input
                onChange={(event) =>
                  onChange('paymentMethod', event.target.value)
                }
                value={instructor.paymentMethod}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                TimeZone
              </Text>
              <Select
                //@ts-ignore
                value={instructor.timezone}
                showSearch
                style={{width: '100%'}}
                placeholder="Select TimeZone"
                optionFilterProp="children"
                onChange={(value) => {
                  onChange('timezone', value);
                }}>
                {avaliableTimeZones?.map((timeZone) => (
                  <Select.Option value={timeZone}>{timeZone}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Program
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                //@ts-ignore
                value={instructor.program?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a Program"
                optionFilterProp="children"
                onChange={(programId) => onChange('program', programId)}>
                {programs?.map((prgm) => (
                  <Select.Option value={prgm.id!}>
                    {JSON.parse(prgm.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Country
              </Text>

              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a country"
                optionFilterProp="children"
                value={selectedCounty}
                onChange={(c) => {
                  // @ts-ignore
                  setSelectedCounty(c);
                  onChange(
                    'currency',
                    // @ts-ignore
                    CountriesWithCurrency.getAllInfoByISO(c).currency,
                  );
                }}>
                {countries?.map((country) => {
                  return (
                    <Select.Option value={country.iso}>
                      {country.countryName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Birthdate
              </Text>
              <DatePicker
                value={instructor.birthDate && moment(instructor.birthDate)}
                onChange={(selectedDate) => {
                  onChange('birthDate', selectedDate.format('YYYY-MM-DD'));
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Currency
              </Text>
              <Input disabled={true} value={instructor.currency} />
            </div>
          </Col>
        </Row>
        <Row>
          {daysOfTheWeek.map((day, index) => (
            <Col span={12}>
              <div style={{margin: 20, flexDirection: 'row'}}>
                <Text style={{display: 'block'}} strong={true}>
                  {day}
                </Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                  }}>
                  <RangePicker
                    style={{marginInlineEnd: 30}}
                    // mode="time"
                    showToday
                    showTime
                    format="HH"
                    onOk={() => {}}
                    value={
                      instructorAvaliablities.some((e: any) => e?.day === index)
                        ? [
                            moment(
                              new Date(
                                '2020-02-01' +
                                  'T' +
                                  instructorAvaliablities.find(
                                    (e: any) => e?.day === index,
                                  )?.startTime,
                              ),
                            ),
                            moment(
                              new Date(
                                '2020-02-01' +
                                  'T' +
                                  instructorAvaliablities.find(
                                    (e: any) => e?.day === index,
                                  )?.endTime,
                              ),
                            ),
                          ]
                        : null
                    }
                    disabled={instructorAvaliablities.some(
                      (e: any) => e?.day === index && e?.id,
                    )}
                    disabledDate={(current) => {
                      var a = moment(); //now
                      var b = moment(current, 'YYYY-MM-DD HH:mm:ss');
                      return a.diff(b, 'days') !== 0;
                    }}
                    separator={'TO'}
                    // placeholder="Select Start Time"
                    onChange={(date) => {
                      let _tempAvaliablities: Array<any> = [
                        ...instructorAvaliablities,
                      ];
                      const findIndex = instructorAvaliablities?.findIndex(
                        (e: any) => e.day === index,
                      );

                      if (date) {
                        let startTime = moment(date[0]).format('HH:mm:ss');
                        let endTime = moment(date[1]).format('HH:mm:ss');

                        if (findIndex === -1) {
                          console.log('NEWWW', findIndex);

                          setInstructorAvaliablities([
                            ...instructorAvaliablities,
                            {
                              __typename: 'Availability',
                              id: '',
                              updatedAt: '',
                              createdAt: '',
                              instructor: null,
                              startTime,
                              endTime,
                              day: index,
                            },
                          ]);
                        } else {
                          console.log('EDITTTTTT', findIndex);
                          _tempAvaliablities[findIndex] = {
                            day: index,
                            startTime,
                            endTime,
                          };
                          setInstructorAvaliablities([..._tempAvaliablities]);
                        }
                      } else if (findIndex !== -1) {
                        _tempAvaliablities.splice(findIndex, 1);
                        setInstructorAvaliablities([..._tempAvaliablities]);
                      }
                    }}
                  />
                  {instructorAvaliablities.some(
                    (e: any) => e?.day === index && e?.id,
                  ) && (
                    <CloseCircleFilled
                      onClick={async () => {
                        const findAvaliablity: Availability =
                          instructorAvaliablities.find(
                            (e: any) => e.day === index,
                          );

                        await instructorService.deleteAvailability(
                          findAvaliablity.id,
                        );

                        let _tempAvaliablities: Array<any> = [
                          ...instructorAvaliablities,
                        ];

                        const findIndex = instructorAvaliablities?.findIndex(
                          (e: any) => e.day === index,
                        );

                        _tempAvaliablities.splice(findIndex, 1);
                        setInstructorAvaliablities([..._tempAvaliablities]);
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Title>Sessions History Last 3 Months</Title>

        <List
          itemLayout="horizontal"
          dataSource={instructorNumberOfStudiesSessions}
          renderItem={(item, index) => (
            <List.Item style={{width: '100%', padding: 10}}>
              <Row
                style={{
                  backgroundColor: '#40B2F2',
                  width: '100%',
                  padding: 10,
                  borderRadius: 5,
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginInline: 20,
                    color: 'white',
                  }}>
                  {item.month + '  '}
                </Text>
                <Text
                  style={{fontSize: 16, fontWeight: 'bold', color: 'white'}}>
                  {'  '}({item.totalNumberOfSession}){' '}
                </Text>
              </Row>
            </List.Item>
          )}
        />
      </>
      <Button
        onClick={async () => {
          try {
            console.log('MOREE_DATQ', instructor.userUpdatedAt);
            const updateUser: UserUpdate = {
              country: selectedCounty,
              timeZone: instructor.timezone,
              whatsappNumber: instructor.whatsapp,
            };

            await instructorService.updateInstructorUser(
              instructor.userId,
              instructor.userUpdatedAt,
              updateUser,
            );

            const assetsService = new AssetsService();
            //@ts-ignore
            const instructorAssetData: Asset = {
              ...instructor.promo,
            };
            if (instructorAssetData) {
              await assetsService.updateAsset(instructorAssetData);
            }
            const instructorObj: InstructorUpdate = {
              name: instructor.name,
              programId: instructor.program.id,
              gender: instructor.gender,
              speakingLanguages: instructor.speakingLanguages,
              isNative: instructor.isNative,
              isActive: instructor.isActive,
              status: instructor.status,
              birthDate: instructor.birthDate,
              paymentMethod: instructor.paymentMethod,
            };
            const UpdatedInstructor = await instructorService.updateInstructor(
              instructor.id,
              instructor.updatedAt,
              instructorObj,
            );
            console.log('instructorAvaliablities', instructorAvaliablities);
            await Promise.all(
              instructorAvaliablities.map(
                async (instrAvaliab: Availability) => {
                  const isInstructorAvaliabiltyCreated: boolean =
                    instrAvaliab.id ? true : false;

                  if (!isInstructorAvaliabiltyCreated) {
                    const instruAvalitilty: AvailabilityInsert = {
                      instructorId: params.instructorId,
                      startTime: instrAvaliab?.startTime,
                      endTime: instrAvaliab.endTime,
                      day: instrAvaliab.day,
                    };

                    return instructorService.createAvailability(
                      instruAvalitilty,
                    );
                  }
                },
              ),
            );
            console.log('UpdatedInstructor', UpdatedInstructor);
            alert('Intructor Updated');
            // window.location.reload();
          } catch (error) {
            console.log('EORRRRR', error);
            alert('Intructor create Error');
          }
        }}>
        Edit
      </Button>
    </div>
  );
};
