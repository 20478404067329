import React, {useState} from 'react';
import AgoraUIKit, {
  CallbacksInterface,
  RtcPropsInterface,
  layout,
} from 'agora-react-uikit';
import {Session, User} from '../../graphql/API';
import {useSelector} from 'react-redux';
import {
  ToggleState,
  UIKitUser,
  remoteTrackState,
} from 'agora-react-uikit/dist/PropsContext';
import {notification} from 'antd';
import {NotificationPlacement} from 'antd/lib/notification';
import {IAgoraRTCRemoteUser} from 'agora-rtc-sdk-ng';

type AgoraPropsInterface = {
  session: Session;
};

export function isArabic(text: string): boolean {
  var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
  const result = pattern.test(text);
  return result;
}

const AgoraVideoCall = (props: AgoraPropsInterface) => {
  const [videoCall, setVideoCall] = useState(true);
  const userInfo: User = useSelector((state: any) => state.user.currentUser);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.open({
      // key,
      message: 'Room Message',
      description: message,
      placement,
    });
  };
  console.log('props.session?.rtcAdminToken', props.session?.rtcAdminToken);
  const rtcProps: RtcPropsInterface = {
    appId: '8d784ed47c1d4e38a66adbcd6c2857e6',
    channel: isArabic(props.session?.name)
      ? 'PikaDo' + props.session.id
      : props.session?.name, // your agora channel
    uid: 6,
    token: props.session?.rtcAdminToken, // use null or skip if using app in testing mode
    enableVideo: true,
    enableAudio: true,
    callActive: true,
    activeSpeaker: true,
    role: 'host',
    layout: layout.pin,
    // disableRtm: true,
  };

  console.log('SESSIONSSSSSSSSSS', rtcProps);
  const callbacks: Partial<CallbacksInterface> = {
    EndCall: () => {
      setVideoCall(false);
      openNotification('topRight', 'Call Ended');
    },
    ActiveSpeaker: (uid: number) => {},
    Screensharing: (state: boolean) => {},
    'update-user-video': () => {},
    // ['user-swap'](user: UIKitUser): ()=>{},
    // ['local-user-mute-video'](status: ToggleState): ()=>{},
    // ['local-user-mute-audio'](status: ToggleState): ()=>{},

    'user-unpublished': (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video',
    ) => {
      console.log('user-unpublished->>', user.hasAudio);
      !user.hasAudio &&
        openNotification(
          'bottomRight',
          `The student has ${
            user.hasAudio ? 'unmuted' : 'muted'
          } The ${mediaType}`,
        );
    },
    'user-published': (
      user: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video',
    ) => {
      console.log('user-unpublished->> UN', user.hasAudio);

      user.hasAudio &&
        openNotification(
          'bottomRight',
          `The student has ${
            user.hasAudio ? 'unmuted' : 'muted'
          } The ${mediaType}`,
        );
    },
  };
  return (
    <>
      {contextHolder}

      {videoCall ? (
        <div
          style={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'black',
          }}>
          <AgoraUIKit
            rtcProps={rtcProps}
            callbacks={callbacks}
            rtmProps={{
              username: userInfo?.instructor?.name || 'PikaDo Team',
              displayUsername: true,
            }}
          />
        </div>
      ) : (
        <a>
          <h3
            style={{
              textAlign: 'center',
              margin: '50px',
              backgroundColor: '#42870C',
              //   width: '75px',
              //   height: '50px',
              padding: '50px',
              color: 'white',
            }}
            onClick={() => setVideoCall(true)}>
            Start Call
          </h3>
        </a>
      )}
    </>
  );
};

export default AgoraVideoCall;
