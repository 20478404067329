import {generateClient, GraphQLResult} from 'aws-amplify/api';

import {IInstructorsRepository} from './IInstructorsRepository';
import {instructors, getInstructor, sessions} from './InstructorQueries';
import {
  AvailabilityInsert,
  Instructor,
  InstructorInsert,
  InstructorUpdate,
  InstructorsConnection,
  InstructorsFilter,
  SessionsFilter,
  Status,
  UserUpdate,
} from '../../graphql/API';
import {signUp, SignUpInput, SignUpOutput} from 'aws-amplify/auth';
import {createUser} from '../../graphql/mutations';
import {
  createAvailability,
  createInstructor,
  updateInstructorUser,
  deleteAvailability,
  updateInstructor,
} from './InstructorMutation';

const client = generateClient();

export class InstructorsRepository implements IInstructorsRepository {
  async getSessionsByInstructorIdFromTo(
    instructorId: string,
    startDateTime: string,
    endDateTime: string,
    status: Status,
    nextToken: string | undefined,
  ): Promise<any> {
    const filter: SessionsFilter = {
      instructorId: {eq: instructorId},
      status: {eq: status},
      startDateTime: {eq: startDateTime},
      endDateTime: {eq: endDateTime},
    };

    const groups: GraphQLResult<any> = await client.graphql({
      query: sessions,
      variables: {
        filter,
        nextToken,
      },
    });

    console.log('groupsdata?.sessions', groups.data.sessions);
    return groups.data?.sessions;
  }
  async deleteAvailability(id: string): Promise<any> {
    return await client.graphql({
      query: deleteAvailability,
      variables: {
        id,
      },
    });
  }
  async createAvailability(availability: AvailabilityInsert): Promise<any> {
    return await client.graphql({
      query: createAvailability,
      variables: {
        input: {
          ...availability,
        },
      },
    });
  }
  async createUser(
    cognitoUserId: string,
    country: string,
    whatsappNumber: string,
    timeZone: string,
  ): Promise<any> {
    const res: GraphQLResult<any> = await client.graphql({
      query: createUser,
      variables: {
        input: {
          cognitoUserId: cognitoUserId,
          country: country,
          whatsappNumber: whatsappNumber,
          timeZone: timeZone,
        },
      },
    });

    console.log('res->', res);
    return res.data.createUser;
  }

  async signUpInstructor(
    email: string,
    password: string,
  ): Promise<SignUpOutput | Error> {
    const input: SignUpInput = {
      username: email,
      password: password,
      options: {
        userAttributes: {email},
        validationData: {
          type: 'INSTRUCTOR',
        },
      },
    };
    return await signUp(input);
  }

  async getInstructors(
    isActiveInstructors: boolean | null,
    nextToken: string | undefined,
  ): Promise<InstructorsConnection> {
    let filter: InstructorsFilter = {};
    if (
      typeof isActiveInstructors !== 'undefined' &&
      isActiveInstructors !== null
    ) {
      filter = {
        isActive: {eq: isActiveInstructors},
      };
    }

    try {
      const instrts: GraphQLResult<any> = await client.graphql({
        query: instructors,
        variables: {
          filter,
          nextToken,
        },
      });
      console.log('getInstructors->', instrts);

      return instrts.data?.instructors;
    } catch (error) {
      console.log('Fetch getInstructors Error: ', error);
      throw new Error(error);
    }
  }

  async getInstructorById(instructorId: string): Promise<any> {
    try {
      const instructorById: GraphQLResult<any> = await client.graphql({
        query: getInstructor,
        variables: {
          id: instructorId,
        },
      });
      console.log('getInstructors->', instructorById);

      return instructorById.data?.instructor;
    } catch (error) {
      console.log('Fetch getInstructors Error: ', error);
      throw new Error(error);
    }
  }

  async createInstructor(instructor: InstructorInsert): Promise<any> {
    try {
      const createInstructorResponse: GraphQLResult<any> = await client.graphql(
        {
          query: createInstructor,
          variables: {
            input: instructor,
          },
        },
      );
      console.log('createInstructorResponse->', createInstructorResponse);

      return createInstructorResponse.data?.createInstructor;
    } catch (error) {
      console.log('Fetch createInstructorResponse Error: ', error);
      throw new Error(error);
    }
  }

  async updateInstructor(
    instructorId: string,
    updatedAt: string,
    instructor: InstructorUpdate,
  ): Promise<any> {
    try {
      const updateInstructorResponse: GraphQLResult<any> = await client.graphql(
        {
          query: updateInstructor,
          variables: {
            id: instructorId,
            updatedAt: updatedAt,
            input: instructor,
          },
        },
      );
      console.log('updateInstructorResponse->', updateInstructorResponse);

      return updateInstructorResponse;
    } catch (error) {
      console.log('Fetch createInstructorResponse Error: ', error);
      throw new Error(error);
    }
  }

  async updateInstructorUser(
    userId: string,
    updatedAt: string,
    user: UserUpdate,
  ): Promise<any> {
    try {
      const updateUserInstructor: GraphQLResult<any> = await client.graphql({
        query: updateInstructorUser,
        variables: {
          id: userId,
          updatedAt: updatedAt,
          input: user,
        },
      });
      console.log('updateInstructorResponse->', updateUserInstructor);

      return updateUserInstructor;
    } catch (error) {
      console.log('Fetch updateUserInstructor Error: ', error);
      throw new Error(error);
    }
  }
}
