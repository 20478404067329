import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spin, Table} from 'antd';
import moment from 'moment';

import {Package, Price} from '../../graphql/API';
import {PricesService} from './PricesService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

const pricesService = new PricesService();

export const PricesTable = () => {
  const [prices, setPrices] = useState<Array<Price> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    pricesService
      .prices()
      .then((resp) => {
        setIsLoading(false);
        setPrices(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any,
  ) => {};

  const columns: any = [
    {
      title: 'Name',
      dataIndex: ['name'],
      render: (name: string, record: Price) => (
        <Link to={'/prices/' + record.id + '/edit'}>{name}</Link>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Country',
      dataIndex: 'price',
      sorter: (a: any, b: any) => {
        return a.price.localeCompare(b.price);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      sorter: (a: any, b: any) => {
        return a.currency.localeCompare(b.currency);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Package',
      dataIndex: ['package'],
      render: (packagee: Package) => (
        <Link to={'/packages/' + packagee.id + '/edit'}>
          {JSON.parse(packagee.name_i18n)['en']}
        </Link>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: (a: any, b: any) => {
        return a.country.localeCompare(b.country);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={'/prices/' + id + '/edit'}>{id}</Link>,
    },
  ];

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/prices/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={prices}
          onChange={onChange}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Prices fetching error ⚠️</Title>}
    </>
  );
};
