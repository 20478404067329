import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment, {Moment} from 'moment';

import {DiscountCodeService} from './DiscountCodeService';
import {useEffect, useRef, useState} from 'react';
import {
  Package,
  Discountcode,
  DiscountcodeConnection,
  DiscountCodeInsert,
  DiscountUsage,
  Level,
  Curriculum,
  Classschool,
  School,
  PikaDoPartners,
} from '../../graphql/API';

import {useDispatch} from 'react-redux';
import {getCurrentUserPermission, Permissions} from '../../utils';
import Text from 'antd/lib/typography/Text';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {CurriculumsService} from '../Curriculums/CurriculumsService';
import {SchoolsDropDown} from '../PromoCodes/SchoolsDropDown';
import {NotificationPlacement} from 'antd/lib/notification';

const discountCodeService = new DiscountCodeService();
const packagesService = new PackagesService();
const levelService = new LevelsService();
const curriculumsService = new CurriculumsService();

export const DiscountCodeTable = () => {
  const dispatch = useDispatch();

  const [discountCodes, setDiscountCodes] = useState<DiscountcodeConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCreationPopUp, setShowCreationPopUp] = useState<boolean>(false);

  const [current, setCurrent] = useState(1);
  const [maxNumReachedPages, setMaxNumReachedPages] = useState(1);

  const [valuePercentage, setValuePercentage] = useState<number>(0);
  const [codeValidFrom, setCodeValidFrom] = useState<string>(
    moment().toISOString(),
  );
  const [codeExpirationDate, setCodeExpirationDate] = useState<string>(
    moment().toISOString(),
  );
  const [discountUsage, setDiscountUsage] = useState<DiscountUsage>();

  const [numOfCodes, setNumOfCodes] = useState<number>(1);
  const Timeout = useRef<any>();
  const [emptySeachValue, setEmptySeachValue] = useState<boolean>(true);

  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package>();
  const [levels, setLevels] = useState<Array<Level> | []>([]);
  const [levelId, setLevelId] = useState<string | undefined>(undefined);
  const [schoolId, setSchoolId] = useState<string | undefined>(undefined);
  const [classId, setClassId] = useState<string | undefined>(undefined);
  const [selectedCurriculum, setSelectedCurriculum] = useState<Curriculum>();
  const [partnerName, setPartnerName] = useState<PikaDoPartners | undefined>(
    undefined,
  );
  const [curriculums, setCurriculums] = useState<Array<Curriculum> | []>([]);

  const [currentUserPermission, setCurrentUserPermission] =
    useState<Permissions>();
  const [isSubscriptionSchool, setIsSubscriptionShool] =
    useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getCurrentUserPermission()
      .then((p: Permissions) => {
        console.log('currentUserPermission', p);
        setCurrentUserPermission(p);
        if (p === 'admin') {
          packagesService
            .getPackages()
            .then((pkgs) => {
              console.log('pkgs', pkgs);
              const livePackages = pkgs;
              console.log('livePackages', livePackages);

              setPackages(livePackages);
            })
            .catch((error) => {
              setIsError(true);
              console.log('Error ->', error);
            });

          curriculumsService
            .getCurriculums()
            .then((resp) => {
              setIsLoading(false);
              setCurriculums(resp);
              const selectedCurriculum = resp?.find(
                (curr: Curriculum) => curr?.id === 'PIKADO',
              );
              setSelectedCurriculum(selectedCurriculum);
            })
            .catch((error) => {
              setIsError(true);
              console.log('Error ->', error);
            });
        }
      })
      .catch((er) => {
        console.log('err->', er);
      });
  }, []);

  useEffect(() => {
    if (emptySeachValue && current && currentUserPermission) {
      if (currentUserPermission === 'admin') {
        discountCodeService
          .getDiscountCodes(discountCodes?.nextToken)
          .then((resp: DiscountcodeConnection) => {
            const currentItems = discountCodes?.items ?? [];
            console.log('discountCodes', resp);

            setDiscountCodes({
              ...discountCodes,
              ...resp,
              items: [...currentItems, ...resp.items],
            });

            setIsLoading(false);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, emptySeachValue, currentUserPermission]);

  const columns: any = [
    {
      title: 'Code',
      dataIndex: 'codeName',
      sorter: (a: any, b: any) => {
        return a.codeName.localeCompare(b.codeName);
      },
    },

    {
      title: 'Package',
      dataIndex: ['package', 'name_i18n'],
      render: (name_i18n: string) => <p>{JSON.parse(name_i18n)['en']}</p>,
    },

    {
      title: 'Status',
      dataIndex: 'isExpired',
      render: (isExpired: boolean) => {
        return (
          <p
            style={{
              backgroundColor: isExpired ? 'red' : 'greenyellow',
              textAlign: 'center',
              padding: 2,
              color: isExpired ? 'white' : 'black',
              fontWeight: 'bold',
              borderRadius: 4,
            }}>
            {isExpired ? 'USED' : 'AVAILABLE'}
          </p>
        );
      },
    },
    {
      title: 'Valid From (UTC)',
      dataIndex: 'validFrom',
      render: (validFrom: string) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {moment(validFrom).format('YYYY-MM-DD hh:mm:ss A')}
        </p>
      ),
    },
    {
      title: 'Expiration at (UTC)',
      dataIndex: 'expirationDate',
      defaultSortOrder: 'descend',
      render: (expirationDate: string) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {moment(expirationDate).format('YYYY-MM-DD hh:mm:ss A')}
        </p>
      ),
      sorter: (a: any, b: any) =>
        moment(a.expirationDate).unix() - moment(b.expirationDate).unix(),
    },
    {
      title: 'Discount Percentage (%)',
      dataIndex: 'valuePercentage',

      render: (valuePercentage: number) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {valuePercentage}
        </p>
      ),
    },
    {
      title: 'Discount Usage',
      dataIndex: 'usage',

      render: (usage: DiscountUsage) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {usage}
        </p>
      ),
    },
    {
      title: 'Student id',
      dataIndex: ['student', 'id'],

      render: (id: string, record: Discountcode) => (
        <Link
          to={{
            pathname: '/students/' + record?.student?.id + '/edit',
            state: {student: record.student},
          }}>
          {id}
        </Link>
      ),
      filterSearch: true,
    },

    {
      title: 'Program',
      dataIndex: ['package', 'program', 'flag'],
      render: (flag: string) => (
        <img style={{width: 60, height: 40}} src={flag} />
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      render: (id: string) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {id}
        </p>
      ),
    },
  ];

  const openErrorNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.error({
      // key,
      message: 'Cannot generate codes',
      description: message,
      placement,
    });
  };

  useEffect(() => {
    if (!isSubscriptionSchool) {
      setLevelId(undefined);
      setSchoolId(undefined);
      setClassId(undefined);
    }

    if (isSubscriptionSchool && levels.length === 0) {
      levelService
        .getLevels()
        .then((lvls) => {
          setLevels(lvls);
        })
        .catch((error) => {
          setIsError(true);
          console.log('Error ->', error);
        });
    }
  }, [isSubscriptionSchool, levels]);

  const onChangeLogic = ({current}, size) => {
    console.log('CURRRRR_sizeChangeLogic', current);

    if (current >= maxNumReachedPages) {
      setCurrent(current);
      setMaxNumReachedPages(current);
    }
  };

  const searchStudents = (nextToken: string, textSearch?: string) => {
    if (textSearch.length > 0 || currentUserPermission === 'editor') {
      discountCodeService
        .getDiscountCodes(nextToken, textSearch)
        .then((codes) => {
          setDiscountCodes({
            ...codes,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('Error ->', error);
        });
    }
  };

  return (
    <>
      {contextHolder}

      <Row
        style={{
          margin: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Col>
          <Input
            style={{marginTop: 10}}
            placeholder="Search Code"
            onChange={(event) => {
              setIsLoading(true);
              if (event.target.value.length === 0) {
                setEmptySeachValue(true);
              } else {
                setEmptySeachValue(false);
              }

              if (Timeout.current) {
                clearTimeout(Timeout.current);
              }

              Timeout.current = setTimeout(() => {
                searchStudents(null, event.target.value);
              }, 1200);
            }}
          />
        </Col>
        {currentUserPermission === 'admin' && !isLoading && (
          <Col>
            <Button size={'large'} onClick={() => setShowCreationPopUp(true)}>
              Create
            </Button>
          </Col>
        )}
      </Row>
      {!isError && !isLoading && (
        <Table
          pagination={{pageSizeOptions: ['100'], pageSize: 100}}
          current={current}
          defaultPageSize={100}
          // onShowSizeChange={sizeChangeLogic}
          onChange={onChangeLogic}
          // showSizeChanger={true}
          columns={columns}
          dataSource={discountCodes?.items}
        />
      )}
      <Modal
        style={{top: 20}}
        visible={showCreationPopUp}
        // closable={true}
        onCancel={() => {
          setShowCreationPopUp(false);
        }}
        okText="Create"
        onOk={async () => {
          if (
            numOfCodes > 0 &&
            numOfCodes <= 100 &&
            selectedPackage?.id &&
            !moment(codeValidFrom).isAfter(codeExpirationDate)
          ) {
            if (
              (schoolId && !classId) ||
              (isSubscriptionSchool && (!schoolId || !classId || !levelId))
            ) {
              openErrorNotification(
                'topRight',
                `If you select subscription you must select the class or level`,
              );

              return;
            }

            setShowCreationPopUp(false);

            const schoolIdd = isSubscriptionSchool ? schoolId : undefined;
            const lvlId = isSubscriptionSchool ? levelId : undefined;

            setShowCreationPopUp(false);
            let subscriptionSchoolInsert = {
              schoolId: schoolIdd,
              classschoolId: classId,
              levelId: lvlId,
              partnerName: partnerName,
            };

            const discountcodeInput: DiscountCodeInsert = {
              numOfCodes,
              valuePercentage,
              packageId: selectedPackage.id,
              validFrom: codeValidFrom,
              expirationDate: codeExpirationDate,
              usage: discountUsage,
              curriculumId: selectedCurriculum?.id,
              ...(isSubscriptionSchool
                ? {...subscriptionSchoolInsert}
                : undefined),
            };
            console.log('discountcodeInput', discountcodeInput);

            setIsLoading(true);

            await discountCodeService.createDiscountCodes(discountcodeInput);

            window.location.reload();
          }
        }}>
        <div>
          <Row>
            <Col span={11} style={{marginInlineEnd: 10}}>
              <Text style={{display: 'block'}} strong={true}>
                Number of discount codes
              </Text>

              <InputNumber
                value={numOfCodes}
                placeholder={'percentage'}
                size="large"
                min={1}
                max={100}
                defaultValue={1}
                onChange={(value) => {
                  console.log('DDD', valuePercentage);
                  setNumOfCodes(value);
                }}
              />

              {numOfCodes <= 0 && (
                <Text
                  style={{
                    display: 'block',
                    fontSize: 10,
                    fontWeight: 'normal',
                    color: 'red',
                  }}
                  strong={true}>
                  Number of codes must be greater than zero and less than 100
                </Text>
              )}
            </Col>
            <Col span={11}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              <Select
                value={selectedPackage?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(pkgId) => {
                  let selectedPackage = packages.find(
                    // @ts-ignore
                    (pkg) => pkg.id === pkgId,
                  );

                  setSelectedPackage(selectedPackage);
                }}>
                {packages?.map((pkg) => (
                  <Select.Option value={pkg.id!}>
                    {JSON.parse(pkg.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>

              {!selectedPackage?.id && (
                <Text
                  style={{
                    display: 'block',
                    fontSize: 10,
                    fontWeight: 'normal',
                    color: 'red',
                  }}
                  strong={true}>
                  field required *
                </Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={13}>
              <Row>
                <Text
                  style={{
                    display: 'block',
                    marginTop: '20px',
                    marginBottom: '10px',
                  }}
                  strong={true}>
                  Valid From
                </Text>
              </Row>
              <Row>
                <DatePicker
                  value={moment(codeValidFrom)}
                  onChange={(value: Moment) => {
                    if (value) {
                      setCodeValidFrom(value.toISOString());
                    }
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                  // disabledDate={disabledDate}
                  // disabledTime={disabledDateTime}
                  showTime={{
                    defaultValue: moment('00:00:00', 'HH:mm:ss'),
                  }}
                />
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <Text
                  style={{
                    display: 'block',
                    marginTop: '20px',
                    marginBottom: '10px',
                  }}
                  strong={true}>
                  Expiration Date
                </Text>
              </Row>
              <Row>
                <DatePicker
                  value={moment(codeExpirationDate)}
                  onChange={(value: Moment) => {
                    if (value) {
                      setCodeExpirationDate(value.toISOString());
                    }
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                  // disabledDate={disabledDate}
                  // disabledTime={disabledDateTime}
                  showTime={{
                    defaultValue: moment('00:00:00', 'HH:mm:ss'),
                  }}
                />
              </Row>
            </Col>
            {moment(codeValidFrom).isAfter(codeExpirationDate) && (
              <Text
                style={{
                  display: 'block',
                  fontSize: 10,
                  fontWeight: 'normal',
                  color: 'red',
                }}
                strong={true}>
                invalid starting date and expiration date
              </Text>
            )}
          </Row>
          <Col span={11} style={{marginTop: 10}}>
            <Text style={{display: 'block'}} strong={true}>
              Value discount percentage
            </Text>

            <InputNumber
              placeholder={'percentage'}
              size="large"
              min={0}
              max={100}
              defaultValue={0}
              onChange={(value) => {
                console.log('DDD', valuePercentage);
                setValuePercentage(value);
              }}
            />

            {!valuePercentage && (
              <Text
                style={{
                  display: 'block',
                  fontSize: 10,
                  fontWeight: 'normal',
                  color: 'red',
                }}
                strong={true}>
                field required*
              </Text>
            )}
          </Col>
          <Row style={{marginTop: 10}}>
            <Col span={11}>
              <Text style={{display: 'block'}} strong={true}>
                Discount Usage
              </Text>
              <Select
                value={discountUsage}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a Usage"
                optionFilterProp="children"
                onChange={(usage) => {
                  setDiscountUsage(usage);
                }}>
                {[DiscountUsage.MULTI, DiscountUsage.ONE]?.map(
                  (discUsage: DiscountUsage) => (
                    <Select.Option value={discUsage!}>
                      {discUsage === DiscountUsage.MULTI
                        ? 'Multiple usage'
                        : 'one time usage'}
                    </Select.Option>
                  ),
                )}
              </Select>

              {!discountUsage && (
                <Text
                  style={{
                    display: 'block',
                    fontSize: 10,
                    fontWeight: 'normal',
                    color: 'red',
                  }}
                  strong={true}>
                  field required *
                </Text>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={11} style={{marginTop: 10}}>
              <Text style={{display: 'block'}} strong={true}>
                Curriculum
              </Text>
              <Select
                defaultValue="PIKADO"
                value={selectedCurriculum?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a Curriculum"
                optionFilterProp="children"
                onChange={(currlId) => {
                  let selectedCurriculum = curriculums?.find(
                    // @ts-ignore
                    (curr) => curr.id === currlId,
                  );

                  setSelectedCurriculum(selectedCurriculum);
                }}>
                {curriculums?.map((curr: Curriculum) => (
                  <Select.Option value={curr.id!}>{curr.name}</Select.Option>
                ))}
              </Select>

              {!selectedCurriculum?.id && (
                <Text
                  style={{
                    display: 'block',
                    fontSize: 10,
                    fontWeight: 'normal',
                    color: 'red',
                  }}
                  strong={true}>
                  field required *
                </Text>
              )}
            </Col>
          </Row>

          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Checkbox
              checked={isSubscriptionSchool}
              onChange={() => setIsSubscriptionShool(!isSubscriptionSchool)}>
              discount code for school
            </Checkbox>

            {isSubscriptionSchool && (
              <>
                <SchoolsDropDown
                  onSelectClass={function (classschool: Classschool): void {
                    setClassId(classschool?.id);
                  }}
                  onSelectSchool={function (school: School): void {
                    setSchoolId(school?.id);
                  }}
                  onSelectPartner={function (partner: PikaDoPartners): void {
                    console.log('partner', partner);
                    setPartnerName(partner);
                  }}
                />
                <div style={{marginTop: 30}}>
                  <Select
                    value={levelId}
                    showSearch
                    style={{width: '100%'}}
                    placeholder="Select a level"
                    optionFilterProp="children"
                    onChange={(lvlId) => setLevelId(lvlId)}>
                    {levels?.map((lvl) => (
                      <Select.Option value={lvl.id!}>{lvl.name}</Select.Option>
                    ))}
                  </Select>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
