import {ISyllablesRepository} from './ISyllablesRepository';
import {
  createSyllable,
  deleteSyllable,
  updateSyllable,
} from '../../graphql/mutations';
import {syllable} from '../../graphql/queries';
import {customSyllables} from './SyllablesQueries';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

const client = generateClient();
export class SyllablesRepository implements ISyllablesRepository {
  async createSyllable(syllable: any): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createSyllable,
        variables: {
          input: {...syllable},
        },
      });

      return response.data?.createSyllable;
    } catch (error) {
      console.log('Fetch student Error: ', error);
    }
  }

  async deleteSyllable(syllableId: string): Promise<any> {
    try {
      const deleteSyllableResponse: GraphQLResult<any> = await client.graphql({
        query: deleteSyllable,
        variables: {
          id: syllableId,
        },
      });

      return deleteSyllableResponse;
    } catch (error) {
      console.log('Fetch student Error: ', error);
    }
  }

  async getSyllableById(syllableId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: syllable,
        variables: {
          id: syllableId,
        },
      });

      return response.data?.syllable;
    } catch (error) {
      console.log('Fetch test Error: ', error);
    }
  }

  async getSyllables(): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: customSyllables,
        variables: {},
      });

      return response.data?.syllables.items;
    } catch (error) {
      console.log('Fetch test Error: ', error);
    }
  }

  async updateSyllable(
    syllableId: string,
    updatedAt: string,
    syllable: any,
  ): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: updateSyllable,
        variables: {
          id: syllableId,
          updatedAt: updatedAt,
          input: syllable,
        },
      });

      return response.data?.syllables.items;
    } catch (error) {
      console.log('Fetch updateSyllable Error: ', error);
    }
  }
}
