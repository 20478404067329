import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {QuestionUpdate} from '../../graphql/API';
import {updateQuestion} from '../../graphql/mutations';

import {IQuestionsRepository} from './IQuestionsRepository';
import {createQuestion} from './QuestionsMutations';
const client = generateClient();
export class QuestionsRepository implements IQuestionsRepository {
  async createQuestion(question: any): Promise<any> {
    console.log('CREEATREING,', question);

    try {
      const createQuestionResponse: GraphQLResult<any> = await client.graphql({
        query: createQuestion,
        variables: {
          input: question,
        },
      });
      console.log('createQuestionResponse->', createQuestionResponse);

      return createQuestionResponse.data?.createQuestion.id;
    } catch (error) {
      console.log('Fetch question Error: ', error);
      throw new Error(error);
    }
  }

  async updateQuestion(
    id: string,
    question: QuestionUpdate,
    updatedAt: string,
  ): Promise<any> {
    try {
      const updateQuestionResponse: GraphQLResult<any> = await client.graphql({
        query: updateQuestion,
        variables: {
          input: question,
          updatedAt,
          id,
        },
      });
      console.log('createQuestionResponse->', updateQuestionResponse);

      return updateQuestionResponse.data?.updateQuestion.id;
    } catch (error) {
      console.log('Updtade question Error: ', error);
      throw new Error(error);
    }
  }
}
