import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Image, Input, Row, Space, Spin, Table} from 'antd';
import moment from 'moment';
import {get, isEqual} from 'lodash';

import {
  InstructorStatus,
  PreInstructor,
  PreInstructorsConnection,
} from '../../graphql/API';
import {PreInstructorService} from './PreInstructorService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ProfilePictureCard from './ProfilePictureCard';

const preInstructorService = new PreInstructorService();

export const PreInstructorsTable = () => {
  const [preInstructors, setPreInstructors] =
    useState<PreInstructorsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  let searchInput = useRef();
  const [filterIndex, setFilterIndex] = useState<number>(0);

  useEffect(() => {
    preInstructorService
      .getPreInstructors(InstructorStatus.NEW, null)
      .then((resp) => {
        setIsLoading(false);
        setPreInstructors(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            // @ts-ignore
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };
  const columns: any = [
    {
      title: 'Pre Instructor Name',
      dataIndex: ['name'],
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (name: string, preInstructor: PreInstructor) => (
        <Text>{preInstructor.name} </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        console.log('a->', a);
        return a.name.localeCompare(b.name);
      },
    },
    // {
    //   title: 'Program',
    //   dataIndex: ['program', 'flag'],
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   render: (flag: string, preInstructor: PreInstructor) => (
    //     <img src={flag} alt="" style={{width: 100, height: 70}} />
    //   ),
    // },

    {
      title: 'Profile Picture',
      dataIndex: ['promo', 'thumbnailUri'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (thumbnailUri: string) => {
        console.log('uri33', thumbnailUri);
        return <ProfilePictureCard thumbnailUri={thumbnailUri} />;
      },
    },
    {
      title: 'Speaking Languages',
      dataIndex: 'speakingLanguages',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (speakingLanguages: string[], preInstructor: PreInstructor) =>
        speakingLanguages?.map((lang) => <Text>{lang} </Text>),
    },
    {
      title: 'WhatsApp',
      key: 'whatsappNumber',
      dataIndex: ['user', 'whatsappNumber'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.user.whatsappNumber.localeCompare(b.user.whatsappNumber);
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: ['status'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status: InstructorStatus) => (
        <Text
          style={{
            backgroundColor:
              status === InstructorStatus.NEW
                ? 'purple'
                : status === InstructorStatus.PENDING
                ? 'orange'
                : '#F2406A',
            color: status === InstructorStatus.NEW ? 'white' : 'white',
            fontWeight: 'bold',
            padding: 5,
            borderRadius: 5,
            fontSize: '13px',
          }}>
          {status}
        </Text>
      ),
    },
    {
      title: 'is Native',
      key: 'isNative',
      dataIndex: ['isNative'],
      render: (name: string, preInstructor: PreInstructor) => (
        <Text
          style={{
            backgroundColor: preInstructor.isNative ? 'purple' : 'blueviolet',
            color: preInstructor.isNative ? 'white' : 'white',
            fontWeight: 'bold',
            padding: 5,
            borderRadius: 5,
            fontSize: '13px',
          }}>
          {preInstructor.isNative ? 'NATIVE_SPEAKER' : 'OTHER'}
        </Text>
      ),
    },
    {
      title: 'TimeZone',
      key: 'timezone',
      dataIndex: ['user', 'timeZone'],
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'id',
      dataIndex: 'id',
      ...getColumnSearchProps('id'),
      render: (id: string) => (
        <Link
          to={{
            pathname: '/preInstructor/' + id + '/edit',
            state: {preInstructorId: id},
          }}>
          {id}
        </Link>
      ),
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const showResultFilterByIndex = (index) => {
    switch (index) {
      case 0: {
        setIsLoading(true);
        setIsError(false);
        setPreInstructors(null);
        const instruStatus: InstructorStatus = InstructorStatus.NEW;

        preInstructorService
          .getPreInstructors(instruStatus, null)
          .then((resp: PreInstructorsConnection) => {
            setIsLoading(false);
            setPreInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
        break;
      }
      case 1: {
        setIsError(false);
        setIsLoading(true);
        setPreInstructors(null);
        const instruStatus = InstructorStatus.PENDING;

        preInstructorService
          .getPreInstructors(instruStatus, null)
          .then((resp: PreInstructorsConnection) => {
            setIsLoading(false);
            setPreInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
        break;
      }

      case 2: {
        setIsError(false);
        setIsLoading(true);
        setPreInstructors(null);
        const instruStatus: InstructorStatus = InstructorStatus.SUSPENDED;

        preInstructorService
          .getPreInstructors(instruStatus, null)
          .then((resp: PreInstructorsConnection) => {
            setIsLoading(false);
            setPreInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
        break;
      }

      case 3: {
        setIsError(false);
        setIsLoading(true);
        setPreInstructors(null);
        const instruStatus: InstructorStatus = InstructorStatus.DECLINED;

        preInstructorService
          .getPreInstructors(instruStatus, null)
          .then((resp: PreInstructorsConnection) => {
            setIsLoading(false);
            setPreInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
        break;
      }

      case 4: {
        setIsError(false);
        setIsLoading(true);
        setPreInstructors(null);

        preInstructorService
          .getPreInstructors(null, null)
          .then((resp: PreInstructorsConnection) => {
            setIsLoading(false);
            setPreInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            console.log('Error ->', error);
          });
        break;
      }
    }
  };
  return (
    <>
      <Row>
        <Button
          onClick={() => {
            setFilterIndex(0);
            showResultFilterByIndex(0);
          }}
          style={{
            width: '15%',
            minHeight: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 0 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          New
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(1);
            showResultFilterByIndex(1);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 1 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
            textAlign: 'center',
            flexWrap: 'wrap',
          }}>
          Pending
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(2);
            showResultFilterByIndex(2);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 2 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          Suspended
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(3);
            showResultFilterByIndex(3);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 3 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          Rejected
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(4);
            showResultFilterByIndex(4);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 4 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          All
        </Button>
      </Row>
      {!isError && !isLoading && (
        <Table
          style={{marginTop: 20}}
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={preInstructors?.items ?? []}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Instructors fetching error ⚠️</Title>}
    </>
  );
};
