import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spin, Table} from 'antd';

import {Package} from '../../graphql/API';
import {PackagesService} from './PackagesService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {resolveI18nObject} from '../../utils';

const packagesService = new PackagesService();

export const PackagesTable = () => {
  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    packagesService
      .getPackages()
      .then((resp) => {
        setIsLoading(false);
        // console.log('getPackagesr response ->->', resp);
        setPackages(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    //console.log('params', pagination, filters, sorter, extra);
  };

  const columns: any = [
    {
      title: 'Name',
      dataIndex: ['name'],
      render: (name: string, record: Package) => {
        return (
          <Link to={`/packages/${record.id}/edit`}>
            {resolveI18nObject(record.name_i18n)}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Is free?',
      dataIndex: ['isFree'],
      render: (isFree: boolean) => (
        <Text style={{}}>{isFree ? 'Yes' : 'No'}</Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.isFree - b.isFree,
    },
    {
      title: 'Only Ai?',
      dataIndex: ['onlyAI'],
      render: (onlyAI: boolean) => (
        <Text style={{}}>{onlyAI ? 'Yes' : 'No'}</Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.onlyAI - b.onlyAI,
    },
    {
      title: 'Program',
      dataIndex: ['program', 'flag'],
      render: (flag: string) => <img src={flag} />,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return JSON.parse(a.program.name_i18n)['en'].localeCompare(
          JSON.parse(b.program.name_i18n)['en'],
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={`/packages/${id}/edit`}>{id}</Link>,
    },
  ];

  /**
   {
      title: 'Started at',
      dataIndex: ['startDate'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        moment(a.startDate).unix() - moment(b.startDate).unix(),
    },

   {
      title: 'Created at',
      dataIndex: ['createdAt'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
   {
      title: 'PlacementStatus',
      dataIndex: 'placementStatus',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.placementStatus.localeCompare(b.placementStatus);
      },
      render: (placementStatus: string) => (
        <p
          style={{
            color:
              placementStatus === 'DONE'
                ? 'green'
                : placementStatus === 'DOING'
                ? '#E2D410'
                : 'red',
          }}>
          {placementStatus}
        </p>
      ),
    },
   {
      title: 'PaymentStatus',
      key: 'paymentStatus',
      dataIndex: 'paymentStatus',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.paymentStatus.localeCompare(b.paymentStatus);
      },
      render: (paymentStatus: string) => (
        <p
          style={{
            color:
              paymentStatus === 'DONE'
                ? 'green'
                : paymentStatus === 'DOING'
                ? '#E2D410'
                : 'red',
          }}>
          {paymentStatus}
        </p>
      ),
    },
   {
      title: 'Student',
      dataIndex: ['student', 'name'],
      render: (studentName: string, record: Package) => <Text></Text>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.student.name.localeCompare(b.student.name);
      },
    },
   */

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/package/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={packages}
          onChange={onChange}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Packages fetching error ⚠️</Title>}
    </>
  );
};
