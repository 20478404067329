import {IDiscountCodesRepository} from './IDiscountCodeRepository';

import {
  DiscountcodeConnection,
  DiscountCodeInsert,
  SubscriptionsByDiscountCode,
} from '../../graphql/API';
import {
  discountcodes,
  discountcodesForPartner,
  getSubscriptionsByDiscountCode,
} from './DiscountCodeQueries';
import {createDiscountCodes} from './DiscountCodeMutation';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class DiscountCodeRepository implements IDiscountCodesRepository {
  async getSubscriptionsByDiscountCode(
    codeName: string,
  ): Promise<SubscriptionsByDiscountCode> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: getSubscriptionsByDiscountCode,
        variables: {
          discountcodeId: codeName,
        },
      });
      console.log('getSubscriptionsByDiscountCode from repository:', response);

      return response.data?.getSubscriptionsByDiscountCode;
    } catch (error) {
      console.log('getDescount Error->', error);
      throw new Error(error);
    }
  }

  async getDiscountCodesByPartner(
    nextToken: string,
    partnerName: string,
  ): Promise<DiscountcodeConnection> {
    const filter = {partnerName: {eq: partnerName}};

    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: discountcodesForPartner,
        variables: {
          filter,
          nextToken,
          // scannedCount: 101,
          // schoolId?: TableStringFilter | null,
        },
      });
      console.log('getDiscountCodesByPartner from repository:', response);

      return response.data?.discountcodes;
    } catch (error) {
      console.log('getDescount Error->', error);
      throw new Error(error);
    }
  }
  async createDiscountCodes(
    discountcodeInput: DiscountCodeInsert,
  ): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createDiscountCodes,
        variables: {
          input: {...discountcodeInput},
        },
      });
      console.log('createDiscountCodes from repository:', response);

      return response.data?.createDiscountCodes;
    } catch (error) {
      console.log('createDiscountCodes repository Error:', error);
      throw new Error(error);
    }
  }

  async getDiscountCodes(
    nextToken: string,
    searchValue?: string,
  ): Promise<DiscountcodeConnection> {
    const filter = {codeName: {eq: searchValue}};

    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: discountcodes,
        variables: {
          filter,
          nextToken,
          ...(searchValue?.length > 0 ? null : {scannedCount: 101}),
        },
      });
      console.log('getDiscountCodes from repository:', response);

      return response.data?.discountcodes;
    } catch (error) {
      console.log('getDescount Error->', error);
      throw new Error(error);
    }
  }
}
