import {useEffect, useState} from 'react';
import {useParams, Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Col, Divider, Row, Spin, Typography} from 'antd';
import {Student} from '../../graphql/API';
import {StudentsService} from './StudentsService';

const studentsService = new StudentsService();

export const StudentDetails = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [student, setStudent] = useState<Student | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {studentId: string} = useParams();
  const history = useHistory();
  const {Title, Text} = Typography;
  const location = useLocation();

  useEffect(() => {
    studentsService
      .getStudentById(params.studentId)
      .then((resp) => {
        setIsLoading(false);
        setStudent(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [location.pathname]);

  console.log('params.studentId', location.pathname);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Student </Title>
      <Button size={'large'} style={{float: 'right'}}>
        <Link
          // @ts-ignore
          to={{
            pathname: '/students/' + student?.id + '/edit',
            state: {student: student},
          }}>
          Edit
        </Link>
      </Button>
      {!isError && !isLoading && (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student name
                </Text>
                <Text>{student?.name}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Parent name
                </Text>
                <Text>{student?.user?.parent?.name}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student enrollments
                </Text>
                {student?.enrollments?.map((e) => {
                  return (
                    <>
                      <Link to={'/enrollments/' + e?.id}>{e?.id}</Link>
                      <Divider />
                    </>
                  );
                })}
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Gender
                </Text>
                <Text>{student?.gender}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Language
                </Text>
                <Link to={'/languages/' + student?.language?.id}>
                  {student?.language?.id}
                </Link>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Age
                </Text>
                <Text>{student?.age}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Joined at
                </Text>
                <Text>{student?.createdAt}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  User
                </Text>
                <Link to={'/users/' + student?.user?.id}>
                  {student?.user?.id}
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Status
                </Text>
                <Text>{student?.status}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Assigned to
                </Text>
                <Text>{student?.assignedTo}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Notes
                </Text>
                <Text>{student?.notes}</Text>
              </div>
            </Col>
          </Row>
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Student fetching error ⚠️</Title>}
    </div>
  );
};
