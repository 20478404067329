import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spin, Table} from 'antd';

import {Program} from '../../graphql/API';
import {ProgramsService} from './ProgramsService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {resolveI18nObject} from '../../utils';

const programsService = new ProgramsService();

export const ProgramsTable = () => {
  const [programs, setPrograms] = useState<Array<Program> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    programsService
      .getPrograms()
      .then((resp) => {
        setIsLoading(false);
        // console.log('getProgramsr response ->->', resp);
        setPrograms(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    //console.log('params', pagination, filters, sorter, extra);
  };

  const columns: any = [
    {
      title: 'Name',
      dataIndex: ['name'],
      render: (name: string, record: Program) => {
        return (
          <Link to={`/programs/${record.id}/edit`}>
            {resolveI18nObject(record.name_i18n)}
          </Link>
        );
      },
    },
    {
      title: 'Flag',
      dataIndex: ['flag'],
      render: (flag: string) => <img src={flag} />,
    },
    {
      title: 'Packages',
      dataIndex: ['packages'],
      render: (packages: any) => {
        return packages?.map((pkg, index) => {
          return (
            <div key={pkg.id}>
              <Link to={`/packages/${pkg.id}/edit`}>
                {JSON.parse(pkg.name_i18n)['en']}
              </Link>
              <br />
            </div>
          );
        });
      },
    },
    {
      title: 'Courses',
      dataIndex: ['courses'],
      render: (courses: any) => {
        return courses?.map((course, index) => {
          return (
            <div key={course.id}>
              <Link to={`/courses/${course.id}/edit`}>{course.name}</Link>
              <br />
            </div>
          );
        });
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={`/programs/${id}/edit`}>{id}</Link>,
    },
  ];

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/program/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          rowKey={(record) => record.id}
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={programs}
          onChange={onChange}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Programs fetching error ⚠️</Title>}
    </>
  );
};
