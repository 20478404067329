import {IPricesRepository} from './IPricesRepository';
import {PackagePrices, price} from './PricesQueries';
import {createPrice, updatePrice} from './PricesMutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class PricesRepository implements IPricesRepository {
  async prices(): Promise<any> {
    try {
      const priceResponse: GraphQLResult<any> = await client.graphql({
        query: PackagePrices,
        variables: {},
      });
      console.log('priceResponse->', priceResponse);

      return priceResponse.data?.prices?.items;
    } catch (error) {
      console.log('Fetch updatePreInstructorResponse Error: ', error);
      throw new Error(error);
    }
  }

  async createPrice(price: any): Promise<any> {
    try {
      const createPriceResponse: GraphQLResult<any> = await client.graphql({
        query: createPrice,
        variables: {
          input: price,
        },
      });
      console.log('createPrice->', createPrice);

      return createPriceResponse.data?.createPrice;
    } catch (error) {
      console.log('Create price Error: ', error);
      throw new Error(error);
    }
  }

  async updatePrice(
    priceId: string,
    updatedAt: string,
    price: any,
  ): Promise<any> {
    try {
      const updatePriceResponse: GraphQLResult<any> = await client.graphql({
        query: updatePrice,
        variables: {
          id: priceId,
          updatedAt: updatedAt,
          input: price,
        },
      });
      console.log('createPrice->', createPrice);

      return updatePriceResponse;
    } catch (error) {
      console.log('Create price Error: ', error);
      throw new Error(error);
    }
  }

  async price(priceId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: price,
        variables: {
          id: priceId,
        },
      });
      console.log('createPrice->', createPrice);

      return response.data?.price;
    } catch (error) {
      console.log('getPackage repository Error:', error);
      throw new Error(error);
    }
  }
}
