import {Col, Input, notification, Row, Select, Spin, Table} from 'antd';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment, {Moment} from 'moment';

import {DiscountCodeService} from '../DiscountCode/DiscountCodeService';
import {useEffect, useRef, useState} from 'react';
import {
  Discountcode,
  DiscountcodeConnection,
  DiscountUsage,
  Level,
  PikaDoPartners,
} from '../../graphql/API';

import {useDispatch} from 'react-redux';
import {getCurrentUserPermission, Permissions} from '../../utils';
import {NotificationPlacement} from 'antd/lib/notification';
import {
  AuthSession,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from 'aws-amplify/auth';

const discountCodeService = new DiscountCodeService();

export const DiscountCodeForPartnerTable = () => {
  const dispatch = useDispatch();

  const [discountCodes, setDiscountCodes] = useState<DiscountcodeConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [current, setCurrent] = useState(1);
  const [maxNumReachedPages, setMaxNumReachedPages] = useState(1);

  const Timeout = useRef<any>();
  const [emptySeachValue, setEmptySeachValue] = useState<boolean>(true);

  const [partnerName, setPartnerName] = useState<PikaDoPartners | undefined>(
    undefined,
  );

  const [currentUserPermission, setCurrentUserPermission] =
    useState<Permissions>();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getCurrentUserPermission().then((p: Permissions) => {
      console.log('currentUserPermission', p);
      setCurrentUserPermission(p);
    });
  }, []);

  useEffect(() => {
    if (emptySeachValue && current && currentUserPermission) {
      if (currentUserPermission === 'partner') {
        fetchUserAttributes().then((authUser: FetchUserAttributesOutput) => {
          if (authUser.preferred_username) {
            discountCodeService
              .getDiscountCodesByPartner(
                discountCodes?.nextToken,
                authUser.preferred_username,
              )
              .then((resp: DiscountcodeConnection) => {
                const currentItems = discountCodes?.items ?? [];
                console.log('discountCodes', resp);

                setDiscountCodes({
                  ...discountCodes,
                  ...resp,
                  items: resp.items,
                });

                setIsLoading(false);
              })
              .catch((error) => {
                setIsError(true);
                console.log('Error ->', error);
              });
          }
        });
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, emptySeachValue, currentUserPermission]);

  const columns: any = [
    {
      title: 'Code',
      dataIndex: 'codeName',
      render: (codeName: string, record: Discountcode) => {
        console.log('record', record);
        return (
          <Link
            to={{
              pathname: '/discount-code-details',
              state: {...record},
            }}>
            {codeName}
          </Link>
        );
      },
      sorter: (a: any, b: any) => {
        return a.codeName.localeCompare(b.codeName);
      },
    },

    {
      title: 'Package',
      dataIndex: ['package', 'name_i18n'],
      render: (name_i18n: string) => <p>{JSON.parse(name_i18n)['en']}</p>,
    },

    {
      title: 'Status',
      dataIndex: 'isExpired',
      render: (isExpired: boolean) => {
        return (
          <p
            style={{
              backgroundColor: isExpired ? 'red' : 'greenyellow',
              textAlign: 'center',
              padding: 2,
              color: isExpired ? 'white' : 'black',
              fontWeight: 'bold',
              borderRadius: 4,
            }}>
            {isExpired ? 'USED' : 'AVAILABLE'}
          </p>
        );
      },
    },
    {
      title: 'Valid From (UTC)',
      dataIndex: 'validFrom',
      render: (validFrom: string) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {moment(validFrom).format('YYYY-MM-DD hh:mm:ss A')}
        </p>
      ),
    },
    {
      title: 'Expiration at (UTC)',
      dataIndex: 'expirationDate',
      defaultSortOrder: 'descend',
      render: (expirationDate: string) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {moment(expirationDate).format('YYYY-MM-DD hh:mm:ss A')}
        </p>
      ),
      sorter: (a: any, b: any) =>
        moment(a.expirationDate).unix() - moment(b.expirationDate).unix(),
    },
    {
      title: 'Discount Percentage (%)',
      dataIndex: 'valuePercentage',

      render: (valuePercentage: number) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {valuePercentage}
        </p>
      ),
    },
    {
      title: 'Discount Level',
      dataIndex: ['level', 'name'],

      render: (levelName: string) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {levelName}
        </p>
      ),
    },
    {
      title: 'School Name',
      dataIndex: ['school', 'name'],

      render: (name: string, record: Discountcode) => (
        <p
          style={{
            textAlign: 'center',
            padding: 2,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: 4,
          }}>
          {name}
        </p>
      ),
      filterSearch: true,
    },

    {
      title: 'Program',
      dataIndex: ['package', 'program', 'flag'],
      render: (flag: string) => (
        <img style={{width: 60, height: 40}} src={flag} />
      ),
    },
  ];

  const openErrorNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.error({
      // key,
      message: 'Cannot generate codes',
      description: message,
      placement,
    });
  };

  const onChangeLogic = ({current}, size) => {
    console.log('CURRRRR_sizeChangeLogic', current);

    if (current >= maxNumReachedPages) {
      setCurrent(current);
      setMaxNumReachedPages(current);
    }
  };

  return (
    <>
      {contextHolder}

      {!isError && !isLoading && (
        <Table
          pagination={{pageSizeOptions: ['100'], pageSize: 100}}
          current={current}
          defaultPageSize={100}
          // onShowSizeChange={sizeChangeLogic}
          onChange={onChangeLogic}
          // showSizeChanger={true}
          columns={columns}
          dataSource={discountCodes?.items}
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>discount codes fetching error ⚠️</Title>}
    </>
  );
};
