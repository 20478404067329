import {ISyllablesService} from './ISyllablesService';
import {SyllablesRepository} from './SyllablesRepository';
import {AssetsService} from '../Asset';
import {
  Asset,
  AssetInsert,
  AssetUpdate,
  Syllable,
  SyllableInsert,
} from '../../graphql/API';

export class SyllablesService implements ISyllablesService {
  syllablesRepository: SyllablesRepository;
  assetsService: AssetsService;
  constructor() {
    this.syllablesRepository = new SyllablesRepository();
    this.assetsService = new AssetsService();
  }

  async createSyllable(
    letters: string,
    correctSyllableAsset: any,
  ): Promise<any> {
    let correctSyllableAssetInsert: AssetInsert = {
      fileUri: correctSyllableAsset.fileUri,
      thumbnailUri: correctSyllableAsset.thumbnailUri,
      type: correctSyllableAsset.type,
    };
    let assetId = await this.assetsService.createAsset(
      correctSyllableAssetInsert,
    );

    let syllable: SyllableInsert = {
      letters: letters,
      correctSyllableId: assetId,
    };

    return await this.syllablesRepository.createSyllable(syllable);
  }

  async deleteSyllable(syllableId: string): Promise<any> {
    return await this.syllablesRepository.deleteSyllable(syllableId);
  }

  async getSyllableById(syllableId: string): Promise<any> {
    return await this.syllablesRepository.getSyllableById(syllableId);
  }

  async getSyllables(): Promise<any> {
    let syllables = await this.syllablesRepository.getSyllables();
    return syllables;
  }

  async updateSyllable(
    syllableId: string,
    updatedAt: string,
    syllable: any,
  ): Promise<any> {
    let asset: any = {
      fileUri: syllable.correctSyllable.fileUri,
      thumbnailUri: syllable.correctSyllable.thumbnailUri,
      type: syllable.correctSyllable.type,
      id: syllable.correctSyllable.id,
      updatedAt: syllable.correctSyllable.updatedAt,
    };
    console.log('asset->', asset);
    await this.assetsService.updateAsset(asset);

    let syllableUpdate: SyllableInsert = {
      letters: syllable.letters,
    };
    console.log('syllableId->', syllableId);
    console.log('updatedAt->', updatedAt);
    console.log('syllableUpdate->', syllableUpdate);

    return await this.syllablesRepository.updateSyllable(
      syllableId,
      updatedAt,
      syllableUpdate,
    );
  }
}
