import {IPromoCodesRepository} from './IPromoCodeRepository';

import {PromocodeConnection} from '../../graphql/API';
import {promocodes} from './PromoCodeQueries';
import {createPromoCodes} from './PromoCodeMutation';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class PromoCodeRepository implements IPromoCodesRepository {
  async createPromoCodes(
    numOfCodes: number,
    packageId: string,
    sessionPerWeek: number,
    schoolId: string | undefined,
    classschoolId: string | undefined,
    levelId: string | undefined,
    curriculumId: string | undefined,
  ): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createPromoCodes,
        variables: {
          numOfCodes: numOfCodes,
          packageId,
          sessionPerWeek,
          schoolId,
          classschoolId,
          levelId,
          curriculumId,
        },
      });
      console.log('createPromoCodes->', response);

      return response.data?.createPromoCodes;
    } catch (error) {
      console.log('Fetch program Error: ', error);
      throw new Error(error);
    }
  }

  async getPromoCodes(
    nextToken: string,
    searchValue?: string,
  ): Promise<PromocodeConnection> {
    try {
      const filter = {codeName: {eq: searchValue}};

      try {
        const response: GraphQLResult<any> = await client.graphql({
          query: promocodes,
          variables: {
            filter,
            nextToken,
            ...(searchValue?.length > 0 ? null : {scannedCount: 101}),
          },
        });
        console.log('createPromoCodes->', response);

        return response.data?.promocodes;
      } catch (error) {
        console.log('Fetch program Error: ', error);
        throw new Error(error);
      }
    } catch (e) {
      console.log('getPromoCodes Error->', e);
    }
  }
}
