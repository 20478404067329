import {ConfigProvider, Select, Spin} from 'antd';
import Title from 'antd/es/typography/Title';

import {useEffect, useState, useRef} from 'react';
import {
  ClassesConnection,
  Classschool,
  PikaDoPartners,
  School,
  SchoolsConnection,
} from '../../graphql/API';

import {useDispatch} from 'react-redux';
import {getCurrentUserPermission, Permissions} from '../../utils';
import {SchoolService} from '../Schools';
import {SmileOutlined} from '@ant-design/icons';

const schoolService = new SchoolService();

type SchoolsDropDownProps = {
  onSelectSchool: (school: School) => void;
  onSelectClass: (classschool: Classschool) => void;
  onSelectPartner?: (partner: PikaDoPartners) => void;
};

export const SchoolsDropDown = ({
  onSelectSchool,
  onSelectClass,
  onSelectPartner,
}: SchoolsDropDownProps) => {
  const dispatch = useDispatch();
  const Timeout = useRef<any>();

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<SchoolsConnection>();
  const [classeschool, setClassesschool] = useState<ClassesConnection>();

  useEffect(() => {
    getCurrentUserPermission()
      .then((p) => {
        if (p === 'admin') {
          searchSchools(null);
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log('err->', er);
      });
  }, []);

  const searchSchools = (nextToken: string, textSearch?: string) => {
    schoolService
      .getSchools(nextToken, textSearch)
      .then((resp) => {
        console.log('schools', resp);

        setSchools({
          ...schools,
          items: [...resp.items],
          nextToken: resp.nextToken,
        });

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error ->', error);
      });
  };
  const onScroll = (event) => {
    var target = event.target;
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setIsLoading(true);
      schoolService
        .getSchools(schools?.nextToken)
        .then((stds) => {
          const currentItems = schools?.items ?? [];
          setSchools({
            ...schools,
            ...stds,
            items: [...currentItems, ...stds.items],
            nextToken: stds.nextToken,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('Error ->', error);
        });
    }
  };

  const customizeRenderEmpty = () => (
    <div style={{textAlign: 'center'}}>
      {isLoading ? (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      ) : (
        <>
          <SmileOutlined style={{fontSize: 20}} />
          <p>No Data</p>
        </>
      )}
    </div>
  );

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Select
          filterOption={false}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          showSearch
          onSearch={(e) => {
            setIsLoading(true);
            if (Timeout.current) {
              clearTimeout(Timeout.current);
            }
            Timeout.current = setTimeout(() => {
              searchSchools(null, e);
            }, 1100);
          }}
          style={{width: '100%'}}
          placeholder={'Select a school'}
          optionFilterProp="children"
          onPopupScroll={onScroll}
          loading={isLoading}
          onChange={(ID: any) => {
            console.log('ONCHANEE', ID);
            onSelectClass(undefined);
            const id = ID;
            let selectedSchool = schools?.items?.find(
              (s: School) => s.id === id,
            );
            console.log('ONCHANEE', selectedSchool);
            onSelectSchool(selectedSchool);
            setIsLoading(true);
            schoolService
              .getClassesBySchoolId(id)
              .then((classess) => {
                console.log('classess', classess.items);
                setClassesschool(classess);
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                console.log('Error ->', error);
              });
          }}>
          {schools?.items?.map((std) => (
            <Select.Option value={std.id}>{std.name}</Select.Option>
          ))}
        </Select>

        <Select
          filterOption={false}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          showSearch
          onSearch={(e) => {
            setIsLoading(true);
            if (Timeout.current) {
              clearTimeout(Timeout.current);
            }
            Timeout.current = setTimeout(() => {
              searchSchools(null, e);
            }, 1100);
          }}
          style={{width: '100%'}}
          placeholder={'Select a class'}
          optionFilterProp="children"
          onPopupScroll={onScroll}
          loading={isLoading}
          onChange={(ID: any) => {
            const id = ID;
            let selectedClass = classeschool?.items?.find(
              (s: Classschool) => s.id === id,
            );

            onSelectClass(selectedClass);
          }}>
          {classeschool?.items?.map((clss: Classschool) => (
            <Select.Option value={clss.id}>{clss.name}</Select.Option>
          ))}
        </Select>

        {typeof onSelectPartner === 'function' && (
          <Select
            filterOption={false}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            showSearch
            onSearch={(e) => {}}
            style={{width: '100%'}}
            placeholder={'Select a partner if exist'}
            optionFilterProp="children"
            onPopupScroll={onScroll}
            loading={isLoading}
            onChange={(partnerName: any) => {
              onSelectPartner(partnerName !== 'none' ? partnerName : undefined);
            }}>
            {Object.values({none: 'none', ...PikaDoPartners}).map((partner) => (
              <Select.Option value={partner}>
                {partner?.toLowerCase()}
              </Select.Option>
            ))}
          </Select>
        )}
      </ConfigProvider>

      {isError && <Title>Schools | Classess fetching error ⚠️</Title>}
    </>
  );
};
